import { Component, HostListener, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Plan } from 'src/app/components/plan/model/plan.model';
import { PlanService } from 'src/app/services/plan/plan.service';
import { ThemeService } from '../../theme.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-plan-basic',
  templateUrl: './plan-basic.component.html',
  styleUrls: ['./plan-basic.component.scss']
})
export class PlanbasicComponent implements OnInit, OnDestroy, OnChanges {
  // Se recibe el arreglo de planes desde el componente padre
  @Input() plans: Plan[] = [];
  @Input() navigation: boolean = true;

  currentIndex = 0;
  visiblePlans: Plan[] = [];
  itemsToShow = 3; // Cantidad de elementos visibles

  isPreview: boolean = false;
  param: any;
  private intervalId: any;

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    public themeService: ThemeService,
    private planService: PlanService
  ) {
    // Detecta si se está en modo preview por la URL
    this.activateRoute.params.subscribe(params => {
      this.isPreview = params["id"] !== undefined;
      if (this.isPreview) {
        this.param = params["id"];
      }
    });
  }

  ngOnInit(): void {
    // Actualiza la cantidad de elementos visibles según el ancho de la ventana
    this.updateItemsToShow(window.innerWidth);

    // Verifica si `plans` ya viene desde el `@Input()`
    if (this.plans && this.plans.length > 0) {
      console.log("PlanbasicComponent - Planes recibidos vía Input:", this.plans);
      this.updateVisiblePlans();
      this.initCarousel();
    } else {
      // Se suscribe al servicio para obtener los planes solo si no hay datos en el Input
      this.planService.obtainPlans.subscribe((plans: Plan[]) => {
        if (plans) {
          this.plans = plans.filter(plan => plan.isVisiblePlan());
          console.log("PlanbasicComponent - Planes recibidos desde servicio:", this.plans);
          this.updateVisiblePlans();
          this.planService.setIsActive(true);
          this.initCarousel();
        }
      });
    }
  }

  // Detecta cambios en los @Input()
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['plans'] && !changes['plans'].firstChange) {
      console.log("PlanbasicComponent - ngOnChanges - Planes actualizados:", this.plans);
      this.updateVisiblePlans();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.updateItemsToShow(event.target.innerWidth);
    this.updateVisiblePlans();
  }

  updateItemsToShow(width: number): void {
    if (width >= 1200) {
      this.itemsToShow = 3; // Pantallas grandes
    } else if (width >= 768) {
      this.itemsToShow = 2; // Pantallas medianas
    } else {
      this.itemsToShow = 1; // Pantallas pequeñas
    }
  }

  initCarousel(): void {
    this.intervalId = setInterval(() => {
      this.nextSlide();
    }, 5000); // Intervalo configurable (5 segundos en este ejemplo)
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  updateVisiblePlans(): void {
    if (!this.plans || this.plans.length === 0) {
      this.visiblePlans = [];
      console.log("PlanbasicComponent - No hay planes disponibles.");
      return;
    }
    
    if (this.plans.length === 1) {
      this.visiblePlans = [this.plans[0]];
      console.log("PlanbasicComponent - Un solo plan disponible:", this.visiblePlans);
      return;
    }

    if (this.plans.length === 2) {
      this.visiblePlans = [this.plans[0], this.plans[1]];
      console.log("PlanbasicComponent - Dos planes disponibles:", this.visiblePlans);
      return;
    }

    this.visiblePlans = [];
    for (let i = 0; i < this.itemsToShow; i++) {
      const index = (this.currentIndex + i) % this.plans.length;
      this.visiblePlans.push(this.plans[index]);
    }

    console.log("PlanbasicComponent - Planes visibles actualizados:", this.visiblePlans);
  }

  prevSlide(): void {
    this.currentIndex = (this.currentIndex - 1 + this.plans.length) % this.plans.length;
    this.updateVisiblePlans();
  }

  nextSlide(): void {
    this.currentIndex = (this.currentIndex + 1) % this.plans.length;
    this.updateVisiblePlans();
  }

  changePalette(palette: string): void {
    this.themeService.setPalette(palette);
  }

  onSelectPlan(plan: Plan): void {
    this.planService.setPlanSelected(plan.getID());
    if (this.isPreview) {
      this.router.navigate(
        ["/preview/" + this.param + "/checkout"],
        { relativeTo: this.activateRoute, queryParamsHandling: "preserve" }
      );
    } else {
      this.router.navigate(
        ["/checkout"],
        { relativeTo: this.activateRoute, queryParamsHandling: "preserve" }
      );
    }
  }
}
