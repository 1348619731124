import { Component, OnInit } from '@angular/core';
import { WebService } from '../services/web.service';
import { TemplateDataService } from '../services/template-data.service';
import { ActivatedRoute } from '@angular/router'; // 👈 Se añade esto

@Component({
  selector: 'app-template-loader',
  templateUrl: './template-loader.component.html',
  styleUrls: ['./template-loader.component.scss']
})
export class TemplateLoaderComponent implements OnInit {
  loading = true;
  plantilla = ''; // 'basic', 'contempo' o 'practic'
  host = window.location.host; // Obtener el host dinámicamente

  constructor(
    private webService: WebService,
    private templateDataService: TemplateDataService,
    private route: ActivatedRoute // 👈 Y esto también
  ) {}

  ngOnInit(): void {

    console.log("pase por aqui Arny")

    // Obtenemos el parámetro 'id' para saber si es vista previa
    this.route.params.subscribe(params => {
      const param = params['id'] !== undefined ? parseInt(params['id'], 10) : -1;

      // URL dinámica basada en el host actual
      let url = `${this.webService.HOST}/onboardingConfig/bydomain/${this.host}/${param}`;
      
      // URL de prueba 
      // let url = `${this.webService.HOST}/onboardingConfig/bydomain/megazohos.billcentric.com/${param}`;

      this.webService.get(url).subscribe({
        next: (response) => {
          // Emite la respuesta para que todos los suscriptores (componentes hijos) la reciban
          this.templateDataService.setResponse(response);

          const portalIdentifier = response?.result?.portal_identifier;

          // Mapeamos el identificador al nombre de la plantilla
          switch (portalIdentifier) {
            case 3:
              this.plantilla = 'contempo';
              break;
            case 2:
              this.plantilla = 'practic';
              break;
            case 1:
              this.plantilla = 'basic';
              break;
            default:
              console.warn('Portal identifier desconocido:', portalIdentifier);
              this.plantilla = 'basic'; // Valor por defecto
          }

          this.loading = false;
        },
        error: (err) => {
          console.error('Error al obtener la plantilla:', err);
          this.loading = false;
        }
      });
    });
  }
}
