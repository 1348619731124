import { Component, HostListener, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Plan } from 'src/app/components/plan/model/plan.model';
import { PlanService } from 'src/app/services/plan/plan.service';
import { ThemeService } from '../../theme.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-plan-practic',
  templateUrl: './plan-practic.component.html',
  styleUrls: ['./plan-practic.component.scss']
})
export class PlanpracticComponent implements OnInit, OnDestroy, OnChanges {
  // Recibimos el arreglo de planes vía Input (desde el componente padre)
  @Input() plans: Plan[] = [];
  @Input() navigation: boolean = true;
  
  isLargeScreen = window.innerWidth >= 768;
  currentIndex = 0;
  visiblePlans: Plan[] = [];
  
  isPreview: boolean = false;
  param: any;
  
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.isLargeScreen = event.target.innerWidth >= 768;
    this.updateVisiblePlans();
  }
  
  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    public themeService: ThemeService,
    private planService: PlanService
  ) {
    // Detectamos si es modo preview para obtener el parámetro de la URL
    this.activateRoute.params.subscribe(params => {
      this.isPreview = params["id"] !== undefined;
      if (this.isPreview) {
        this.param = params["id"];
      }
    });
  }
  
  initCarousel(): void {
    // Aquí va la lógica para inicializar el carousel, si la tienes
    console.log("PlanpracticComponent - Carousel inicializado.");
  }
  
  ngOnInit(): void {
    console.log("PlanpracticComponent - ngOnInit iniciado.");
    // Si el padre envía los planes vía Input, se usan directamente:
    if (this.plans && this.plans.length > 0) {
      console.log("PlanpracticComponent - Planes recibidos vía Input:", this.plans);
      this.updateVisiblePlans();
      this.initCarousel();
    } else {
      // En caso de no recibir Input, nos suscribimos al servicio de planes
      this.planService.obtainPlans.subscribe((data: any) => {
        console.log("PlanpracticComponent - Datos recibidos desde planService:", data);
        // Accedemos a la propiedad 'plans' usando notación con corchetes
        this.plans = ((data as any)['plans'] as Plan[]).filter((plan: Plan) => plan.isVisiblePlan());
        console.log("PlanpracticComponent - Planes filtrados:", this.plans);
        this.updateVisiblePlans();
        this.planService.setIsActive(true);
        this.initCarousel();
      });
    }
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['plans'] && !changes['plans'].firstChange) {
      console.log("PlanpracticComponent - ngOnChanges - Planes actualizados:", this.plans);
      this.updateVisiblePlans();
    }
  }
  
  ngOnDestroy(): void {
    console.log("PlanpracticComponent - ngOnDestroy.");
    // Aquí podrías limpiar suscripciones si es necesario.
  }
  
  updateVisiblePlans(): void {
    if (!this.plans || this.plans.length === 0) {
      this.visiblePlans = [];
      console.log("PlanpracticComponent - updateVisiblePlans: No hay planes disponibles.");
      return;
    }
    if (this.plans.length === 1) {
      this.visiblePlans = [this.plans[0]];
      console.log("PlanpracticComponent - updateVisiblePlans: Solo un plan disponible:", this.visiblePlans);
      return;
    }
    if (this.plans.length === 2) {
      this.visiblePlans = [this.plans[0], this.plans[1]];
      console.log("PlanpracticComponent - updateVisiblePlans: Dos planes disponibles:", this.visiblePlans);
      return;
    }
    const startIndex = this.currentIndex % this.plans.length;
    const endIndex = startIndex + (this.isLargeScreen ? 3 : 1);
    if (endIndex > this.plans.length) {
      this.visiblePlans = [
        ...this.plans.slice(startIndex, this.plans.length),
        ...this.plans.slice(0, endIndex - this.plans.length)
      ];
    } else {
      this.visiblePlans = this.plans.slice(startIndex, endIndex);
    }
    console.log("PlanpracticComponent - updateVisiblePlans: Visible plans actualizados:", this.visiblePlans);
  }
  
  prevSlide(): void {
    this.currentIndex = (this.currentIndex - 1 + this.plans.length) % this.plans.length;
    this.updateVisiblePlans();
  }
  
  nextSlide(): void {
    this.currentIndex = (this.currentIndex + 1) % this.plans.length;
    this.updateVisiblePlans();
  }
  
  changePalette(palette: string): void {
    this.themeService.setPalette(palette);
  }
  
  onSelectPlan(plan: Plan): void {
    this.planService.setPlanSelected(plan.getID());
    console.log("PlanpracticComponent - onSelectPlan: Plan seleccionado con ID:", plan.getID());
    if (this.isPreview) {
      this.router.navigate(
        ["/preview/" + this.param + "/checkout"],
        { relativeTo: this.activateRoute, queryParamsHandling: "preserve" }
      );
    } else {
      this.router.navigate(
        ["/checkout"],
        { relativeTo: this.activateRoute, queryParamsHandling: "preserve" }
      );
    }
  }
}
