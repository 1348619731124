<div class="content-dash" [ngClass]="themeService.currentPalette">
  <div class="banner-dash"></div>
  <div class="dash-column">
    <div class="column">
      <div class="perfil">
        <img [src]="imagenAleatoria" alt="Foto de perfil" />
        <p>{{ nombreUsuario }}</p>
      </div>
      <div class="profile-data">
        <hr>
        <span><b>{{ 'DASHBOARD.PROFILE.PHONE' | translate }}</b></span>
        <p>{{ telefonoUsuario }}</p><br>
        <span><b>{{ 'DASHBOARD.PROFILE.EMAIL' | translate }}</b></span>
        <p>{{ correoUsuario }}</p>
      </div>
    </div>
    <div class="column">
      <div class="menu-dash">
        <button mat-icon-button class="icon" routerLink="/dashboard/dashboard-content" routerLinkActive="active-button" [routerLinkActiveOptions]="{ exact: true }" aria-label="">
          <span>{{ 'DASHBOARD.MENU.DASHBOARD' | translate }}</span>
        </button>
        <button mat-icon-button class="icon" routerLink="/dashboard/direcciones" routerLinkActive="active-button" [routerLinkActiveOptions]="{ exact: true }" aria-label="">
          <span>{{ 'DASHBOARD.MENU.ADDRESSES' | translate }}</span>
        </button>
        <button mat-icon-button class="icon" routerLink="/dashboard/tarjetas" routerLinkActive="active-button" [routerLinkActiveOptions]="{ exact: true }" aria-label="">
          <span>{{ 'DASHBOARD.MENU.CARDS' | translate }}</span>
        </button>
        <button mat-icon-button class="icon" routerLink="/dashboard/suscripciones" routerLinkActive="active-button" [routerLinkActiveOptions]="{ exact: true }" aria-label="">
          <span>{{ 'DASHBOARD.MENU.SUBSCRIPTIONS' | translate }}</span>
        </button>
      </div>
      <br>
      <p>
        {{ 'DASHBOARD.WELCOME_MESSAGE' | translate: { empresa: nombreEmpresa } }}
      </p>
    </div>
  </div>
</div>
