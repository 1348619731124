<div class="main"  [ngClass]="themeService.currentPalette">
    
    
    <div class="login-modal-content">
        <div class="modal-login">
            <div class="background-white">
                <form  [formGroup]="form" (ngSubmit)="signin()" >

                    <h1>{{ 'LOGIN.TITLE' | translate }}</h1>
                    <input [formControl]="form.controls['username']"   type="text" placeholder="{{ 'LOGIN.EMAIL_PLACEHOLDER' | translate }}">
                    <small 
                    *ngIf="form.controls['username'].hasError('required') && form.controls['username'].touched" 
                    class="form-error-msg">  {{ 'LOGIN.EMAIL_REQUIRED' | translate }}</small>

                    <input [formControl]="form.controls['password']"  type="password" placeholder="{{ 'LOGIN.PASSWORD_PLACEHOLDER' | translate }}">
                    <small 
                    *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched" 
                    class="form-error-msg"> {{ 'LOGIN.PASSWORD_REQUIRED' | translate }}</small>

                    <p>{{ 'LOGIN.FORGOT_PASSWORD' | translate }}</p>
                    <a (click)="onforgotClick()">{{ 'LOGIN.CLICK_HERE' | translate }}</a>
                    <div class="options">
                        <!-- <div class="checkbox">
                            <input type="checkbox" id="checkbox-remember" class="cookies">
                            <label for="checkbox-remember">Recuerdame<br>en este sitio</label>
                        </div> -->
                        <button>{{ 'LOGIN.BUTTON_LOGIN' | translate }}</button>
                    </div>

                </form>
               
            </div>
            <div class="transparent-background">
                <p>{{ 'LOGIN.NEW_ACCOUNT' | translate }}</p>
                <button (click)="onRegisterClick()">{{ 'LOGIN.BUTTON_REGISTER' | translate }}</button>
            </div>
        </div>
    </div>
    <img src="../../assets/images/grafica2.png" alt="graff">
</div>