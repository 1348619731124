<div class="column" [ngClass]="themeService.currentPalette">
    <div class="logo-bank">
        <img src="/assets/images/map.png" alt="{{ 'ADDRESS.MAP_ALT' | translate }}">
    </div>
    <div class="credit_data">
        <h4 class="tx_tipo">{{ direction.address }}</h4>
        <p>{{ direction.provinceName }}, {{ direction.corregimientoName }}</p>
        <p>{{ direction.phone == null ? ('ADDRESS.NOT_AVAILABLE' | translate) : direction.phone }}</p>
        <span class="verified">{{ direction.status == 1 ? ('ADDRESS.STATUS.ACTIVE' | translate) : ('ADDRESS.STATUS.INACTIVE' | translate) }}</span>
    </div>
    <div class="button-action">
        <div *ngIf="direction.status == 1">
            <button (click)="changeStatus(2)" class="outline w-100 btn btn-primary btn-lg">
                {{ 'ADDRESS.BUTTON.DEACTIVATE' | translate }}
            </button>
        </div>
        <div *ngIf="direction.status == 2">
            <button (click)="changeStatus(1)" class="outline w-100 btn btn-primary btn-lg">
                {{ 'ADDRESS.BUTTON.ACTIVATE' | translate }}
            </button>
        </div>
        <div>
            <button (click)="sendToEdit(direction)" class="w-100 btn btn-primary btn-lg">
                {{ 'ADDRESS.BUTTON.UPDATE' | translate }}
            </button>
        </div>
    </div>
</div>
