<div class="content-dash">
  <div class="title-top">
    <h1>Mis Tarjetas</h1>
    <button (click)="toggleAddCardForm()">
      <img src="/assets/icons/plus.svg" alt="">Añadir Tarjeta
    </button>
  </div>
  
  <!-- Mostrar solo las tarjetas correspondientes a la página actual -->
  <app-creditcard-info 
      *ngFor="let t of tdc.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)" 
      [tarjeta]="t" 
      (manageStatus)="changeStatus($event)">
  </app-creditcard-info>
  
  <!-- Indicador de carga -->
  <div class="loading" *ngIf="loadingMore">
    Cargando datos...
  </div>
  
  <!-- Controles de paginación -->
  <div class="pagination">
    <button (click)="previousPage()" [disabled]="currentPage === 1">Anterior</button>
    <span>{{paginationDisplay}}</span>
    <button (click)="nextPage()" [disabled]="currentPage === totalPages && allDataLoaded">
      Siguiente
    </button>
  </div>
</div>
