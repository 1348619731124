import { HttpParams } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from 'src/app/services/loader.service';
import { LoginService } from 'src/app/services/login/login.service';
import { NotificationService } from 'src/app/services/notification.service';
import { WebService } from 'src/app/services/web.service';
import { ThemeService } from '../../theme.service';

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.scss']
})
export class LoginPopupComponent {

  themeColors: any; 
  resetForm: FormGroup;// Nuevo FormGroup para restablecimiento de contraseña
  form : any;
  fromCheckout : Boolean = false;
  isLoginFromCheckout : Boolean = false;

  showForgotPassword = false; //nuevo code intercalar
  toggleForgotPassword() {
    this.showForgotPassword = !this.showForgotPassword;//uevo code intercalar
    //console.log('Estado de showForgotPassword:', this.showForgotPassword);
}

  constructor(public dialogRef: MatDialogRef<LoginPopupComponent>,@Inject(MAT_DIALOG_DATA) public data: any,public themeService: ThemeService,public loginService : LoginService,private web : WebService, private notification : NotificationService, private loader : LoaderService) {
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required,Validators.email]),
      password: new FormControl('', Validators.required)
    });

    this.resetForm = new FormGroup({ // Inicialización del FormGroup para restablecimiento
      email: new FormControl('', [Validators.required, Validators.email]),
      partner: new FormControl('', Validators.required),
      service: new FormControl('', Validators.required)
    });

    if(loginService.getFromCheckout() != null){
      this.form.controls["username"].setValue(loginService.getFromCheckout());
      this.isLoginFromCheckout = true;
      if(loginService.getPassFromCheckout() != null){
        this.form.controls["password"].setValue(loginService.getPassFromCheckout());
        this.signin();
      }
      loginService.removeFromCheckout();
    }

  }
  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }


  signin(){

    let step:any = localStorage.getItem("step");
       
    // if(step === "false") {
    //   return this.notification.showError("ssssPor favor, culmina el pago de tu portal para que se pueda desplegar correctamente...");
    // }
    
    if(!this.form.valid){
      return this.notification.showError("Por favor complete el formulario...");
    }

    this.loader.open();

    const signinData = this.form.value;

    let params = new HttpParams() ;
   
		params = params.set('grant_type', 'password');
		params = params.set('username', signinData.username);
		params = params.set('password', signinData.password);
		params = params.set('client_id', 'web_site');
    params = params.set('partner_id', Number(localStorage.getItem("partner")));

    //SOLICITAMOS TOKEN
    this.web.auth(params,this.web.HOST + "/oauth/token").subscribe( res =>{
      
      this.loader.close();
      localStorage.setItem('sesion',JSON.stringify(res));

      this.dialogRef.close(1);
      

    }, err =>{
      //console.log(err);
      this.loader.close();
      this.dialogRef.close(0);
      this.notification.showError(err.error);
    });
  
  }
  reset() {
    this.resetForm.controls['partner'].setValue(+localStorage.getItem("partner"));
    this.resetForm.controls['service'].setValue(+localStorage.getItem("service"));

    if (!this.resetForm.valid) {
      //console.log('Errores en el formulario:', this.resetForm.errors);
      return this.notification.showError("Por favor complete el formulario...");
    }
    //console.log('Intentando restablecer contraseña con:', this.resetForm.value);
    this.loader.open();
    this.web.post(this.resetForm.value, this.web.HOST + "/onboarding/user/resetPassword").subscribe(response => {
      //console.log("DENTRO")
      this.loader.close();
      this.notification.showSuccess("Se enviara un nuevo password a su correo, por favor inicie sesión con sus credenciales nuevas.");
      this.toggleForgotPassword();
    }, err => {
      this.loader.close();
      //console.log("ERROR")
      if (err.code == "not_found") {
        this.notification.showError("Disculpe, este correo no posee cuenta asociada");
      } else {
        this.notification.showError(err.message);
      }
    });
  }
  closeDialog(): void {
    this.dialogRef.close(0);

  }

}
