<div class="content-dash">
    <div class="title-top">
        <h1>{{ 'ADDRESSES.TITLE' | translate }}</h1>
        <button (click)="toggleAddCardForm()">
            <img src="/assets/icons/plus.svg" alt="">{{ 'ADDRESSES.NEW_ADDRESS' | translate }}
        </button>
    </div>
    
    <!-- Mostrar solo las direcciones en la página actual -->
    <app-address 
        *ngFor="let add of addresses.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)" 
        [direction]="add" 
        (manageStatus)="changeStatus($event)" 
        (editDirection)="update($event)">
    </app-address>

    <!-- Controles de paginación -->
    <div class="pagination">
        <button (click)="previousPage()" [disabled]="currentPage === 1">{{ 'PAGINATION.PREVIOUS' | translate }}</button>
        <span>{{ 'PAGINATION.PAGE' | translate }} {{currentPage}} {{ 'PAGINATION.OF' | translate }} {{totalPages}}</span>
        <button (click)="nextPage()" [disabled]="currentPage === totalPages">{{ 'PAGINATION.NEXT' | translate }}</button>
    </div>
</div>
