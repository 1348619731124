<div class="content-dash">
    <h1>{{ 'PASSWORD.CHANGE.TITLE' | translate }}</h1>
    <form [formGroup]="form" class="change_pass">
        <div class="form-group">
            <label for="currentPassword">{{ 'PASSWORD.CHANGE.CURRENT_PASSWORD_LABEL' | translate }}</label>
            <input 
                autocomplete="current-password" 
                [formControl]="form.controls['actual_password']" 
                type="password" 
                id="currentPassword" 
                name="currentPassword" 
                [placeholder]="'PASSWORD.CHANGE.CURRENT_PASSWORD_PLACEHOLDER' | translate" 
                required>
            <small *ngIf="form.controls['actual_password'].hasError('required') && form.controls['actual_password'].touched" class="form-error-msg">
                {{ 'PASSWORD.CHANGE.CURRENT_PASSWORD_REQUIRED' | translate }}
            </small>
        </div>

        <div class="form-group">
            <label for="newPassword">{{ 'PASSWORD.CHANGE.NEW_PASSWORD_LABEL' | translate }}</label>
            <input 
                autocomplete="new-password" 
                [formControl]="form.controls['new_password']" 
                type="password" 
                id="newPassword" 
                name="newPassword" 
                [placeholder]="'PASSWORD.CHANGE.NEW_PASSWORD_PLACEHOLDER' | translate" 
                required>
            <small *ngIf="form.controls['new_password'].hasError('required') && form.controls['new_password'].touched" class="form-error-msg">
                {{ 'PASSWORD.CHANGE.NEW_PASSWORD_REQUIRED' | translate }}
            </small>
            <small *ngIf="form.controls['new_password'].hasError('minlength') && form.controls['new_password'].touched" class="form-error-msg">
                {{ 'PASSWORD.CHANGE.NEW_PASSWORD_MINLENGTH' | translate }}
            </small>
        </div>

        <div class="form-group">
            <label for="confirmPassword">{{ 'PASSWORD.CHANGE.CONFIRM_PASSWORD_LABEL' | translate }}</label> 
            <input 
                autocomplete="new-password" 
                [formControl]="form.controls['newPasswordConfirm']" 
                type="password" 
                id="confirmPassword" 
                name="confirmPassword" 
                [placeholder]="'PASSWORD.CHANGE.CONFIRM_PASSWORD_PLACEHOLDER' | translate" 
                required>
            <small *ngIf="form.controls['newPasswordConfirm'].hasError('required') && form.controls['newPasswordConfirm'].touched" class="form-error-msg">
                {{ 'PASSWORD.CHANGE.CONFIRM_PASSWORD_REQUIRED' | translate }}
            </small>
            <small *ngIf="form.controls['newPasswordConfirm'].hasError('minlength') && form.controls['newPasswordConfirm'].touched" class="form-error-msg">
                {{ 'PASSWORD.CHANGE.CONFIRM_PASSWORD_MINLENGTH' | translate }}
            </small>
        </div>

        <button (click)="send()">{{ 'PASSWORD.CHANGE.SUBMIT_BUTTON' | translate }}</button>
    </form>
</div>
