<button class="close" mat-icon-button (click)="closeDialog()">
  <mat-icon>close</mat-icon>
</button>
<div class="pop-up-padding">  
    <h2>{{ 'ADDRESSES.TITLE' | translate }}</h2>
    <div class="container mat-elevation-z8">
      <mat-table #table [dataSource]="dataSource">
    
        <!-- Nombre Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> {{ 'ADDRESSES.TABLE.NAME' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.customer.name_customer }}  </mat-cell>
        </ng-container>
    
        <!-- Dirección Column -->
        <ng-container matColumnDef="adress">
          <mat-header-cell *matHeaderCellDef> {{ 'ADDRESSES.TABLE.ADDRESS' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.adress}} </mat-cell>
        </ng-container>
    
        <!-- Acciones Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> {{ 'ADDRESSES.TABLE.ACTIONS' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element"> 
            <button mat-button (click)="select(element)"> {{ 'ADDRESSES.TABLE.SELECT' | translate }} </button>
          </mat-cell>
        </ng-container>
    
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    
      <mat-paginator #paginator
                     [pageSize]="5">
      </mat-paginator>
    </div>
</div>
