import { Component, OnInit, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { TemplateDataService } from './services/template-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private titleService: Title,
    private templateDataService: TemplateDataService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit(): void {
    this.templateDataService.getResponse().subscribe(response => {
      // 1. Cambiar título
      if (response?.result?.domain_portal) {
        this.titleService.setTitle(response.result.domain_portal);
      }

      // 2. Cambiar favicon usando 'logo'
      const logoUrl = response?.result?.onboardingImages?.logo;
      if (logoUrl) {
        // Busca si ya existe un link con id="dynamic-favicon", si no lo crea
        let link: HTMLLinkElement = this.doc.querySelector('#dynamic-favicon');
        if (!link) {
          link = this.doc.createElement('link');
          link.id = 'dynamic-favicon';
          link.rel = 'icon';
          this.doc.head.appendChild(link);
        }
        // Actualiza la URL del favicon
        link.href = logoUrl;
        // Dependiendo del formato de tu imagen, puedes especificar el type:
        // link.type = 'image/svg+xml'; // por ejemplo, si es un SVG
      }
    });
  }
}
