import { ChangeDetectorRef,Renderer2,Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { WebService } from 'src/app/services/web.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LoaderService } from 'src/app/services/loader.service';
import { interval, Observable, of, timer,Subject } from 'rxjs';
import { UniqueCodeService } from 'src/app/checkout/unique-code.service';
import { DOCUMENT } from '@angular/common';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FacPopupComponent } from '../fac-popup/fac-popup.component';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, map, switchMap, delay,takeWhile  } from 'rxjs/operators';
import { AppDialogContainerComponent } from '../../checkout/app-dialog-container/app-dialog-container.component'; // Importa tu componente aquí
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-add-card-form',
  templateUrl: './add-card-form.component.html',
  styleUrls: ['./add-card-form.component.scss']
})
export class AddCardFormComponent implements OnInit {
  form: FormGroup;
  items: Array<any> = [];
  // Valores para el select de meses
  meses = [
    {desc : 'Enero', value : '01'},
    {desc : 'Febrero', value : '02'},
    {desc : 'Marzo', value : '03'},
    {desc : 'Abril', value : '04'},
    {desc : 'Mayo', value : '05'},
    {desc : 'Junio', value : '06'},
    {desc : 'Julio', value : '07'},
    {desc : 'Agosto', value : '08'},
    {desc : 'Septiembre', value : '09'},
    {desc : 'Octubre', value : '10'},
    {desc : 'Noviembre', value : '11'},
    {desc : 'Diciembre', value : '12'},
  ];
  
  // Valores para el select de años
  years: Array<{ desc: string, value: string } > = [];

      // NECESARIOS PARA 3DS DINAMICO
      retryCount = 0;
      maxRetries = 5;
      uniqueCode: string = "";
      myIp: string = '189.127.166.102';
      myHeight: number;
      myWidth: number;
      jwtTokenForThird: string;
      transactionIdForThird: string;
      transactionId: string;
      iframeValue: string;
      iframeName: string;
      iframeUrl: string;
      redirectUrl: string;
      dataObject: any;
      private pollingSuccess = new Subject<void>();
      iframeElement: HTMLIFrameElement;
    creditCardId: any;
    chp : any;
    hasPlanID: any;
  refundJson: { creditCardId: any; sop: any; call: string; };
  constructor(
    private cdr: ChangeDetectorRef,
    private uniqueCodeService: UniqueCodeService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddCardFormComponent>,
    private fb: FormBuilder,
    private auth: AuthService,
    private webService: WebService,
    private notification: NotificationService,
    private loader: LoaderService,
  ) {
    this.form = this.fb.group({
      nameHolderCreditCard: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]], // Solo letras y espacios
      numberCreditCard: ['', [Validators.required, this.validateCreditCardNumber]], // Validación personalizada usando Luhn
      expMonthCreditCard: ['', Validators.required],
      expYearCreditCard: ['', Validators.required],
      ccv: ['', [Validators.required, Validators.pattern(/^[0-9]{3,4}$/)]], // CVV de 3 o 4 dígitos
      street: ['', [Validators.required, Validators.minLength(3)]],
      city: ['', [Validators.required, Validators.minLength(3)]],
      postalCode: ['', [Validators.required, Validators.pattern(/^[0-9]*$/), Validators.minLength(5), Validators.maxLength(6)]], // Solo números de 5 o 6 dígitos
      phone: ['', [Validators.required, Validators.pattern(/^[0-9]*$/), Validators.minLength(10), Validators.maxLength(15)]], // Solo números de 10 a 15 dígitos
    });

    this.initializeYears();
  }
  formatCreditCardNumber(): void {
    const control = this.form.get('numberCreditCard');
    if (!control) return;
  
    let value = control.value.replace(/[^0-9]/g, ''); // Eliminar caracteres no numéricos
  
    if (value.length > 16) {
      value = value.substring(0, 16); // Limitar a 19 caracteres
    }
  
    // Agregar espacios automáticamente cada 4 dígitos
    const formattedValue = value.match(/.{1,4}/g)?.join(' ') || value;
    control.setValue(formattedValue, { emitEvent: false }); // Actualizar el valor del campo sin emitir eventos adicionales
  }
  validateCreditCardNumber = (control: FormControl): { [key: string]: any } | null => {
    const value = control.value.replace(/[^0-9]/g, ''); // Eliminar todos los caracteres no numéricos
    if (!value) return { required: true };
  
    
    // Comprobar si tiene 15 o 16 dígitos
    if (value.length !== 15 && value.length !== 16) {
      return { invalidLength: true };
    }
  
    // Validar usando el Algoritmo de Luhn
    if (!this.luhnCheck(value)) {
      return { invalidCardNumber: true };
    }
  
    return null;
  }
  
  // Algoritmo de Luhn para validar el número de tarjeta
  luhnCheck(cardNumber: string): boolean {
    let sum = 0;
    let shouldDouble = false;

    for (let i = cardNumber.length - 1; i >= 0; i--) {
      let digit = parseInt(cardNumber.charAt(i), 10);

      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }

    return (sum % 10) === 0;
  }
    // SETUP
    ngOnInit(): void {
      this.loader.open();
      this.initializeDimensions();
      this.subscribeToPollingSuccess();
      this.loader.close();
    }
  
    ngAfterViewInit(): void {
      setTimeout(() => {
        const uniqueCode = this.generateUniqueCode();
        this.uniqueCodeService.setUniqueCode(uniqueCode);
        this.uniqueCode = uniqueCode;
        this.addUniqueCodeToHead(uniqueCode);
        this.addUniqueCodeToBody(uniqueCode);
        this.cdr.detectChanges();
      }, 0);
    }
  
  
    private addUniqueCodeToHead(uniqueCode: string): void {
      const comment = this.renderer.createComment('script head nico');
      this.renderer.appendChild(this.document.head, comment);
      const script = this.renderer.createElement('script');
      this.renderer.setAttribute(script, 'type', 'text/javascript');
      this.renderer.setAttribute(script, 'src', `https://h.online-metrix.net/fp/tags.js?org_id=${environment.orgId}&session_id=bg_avocadoprime${uniqueCode}`);
      this.renderer.appendChild(this.document.head, script);
  
      script.onload = () => //console.log('Script loaded successfully.');
      script.onerror = () => console.error('Failed to load the script.');
    }
  
    private addUniqueCodeToBody(uniqueCode: string): void {
      const noscript = this.renderer.createElement('noscript');
      const iframe = this.renderer.createElement('iframe');
      this.renderer.setStyle(iframe, 'width', '100px');
      this.renderer.setStyle(iframe, 'height', '100px');
      this.renderer.setStyle(iframe, 'border', '0');
      this.renderer.setStyle(iframe, 'position', 'absolute');
      this.renderer.setStyle(iframe, 'top', '-5000px');
      this.renderer.setAttribute(iframe, 'src', `https://h.online-metrix.net/fp/tags?org_id=${environment.orgId}&session_id=bg_avocadoprime${uniqueCode}`);
      this.renderer.appendChild(noscript, iframe);
      this.renderer.appendChild(this.document.body, noscript);
    }
  
    private generateUniqueCode(): string {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }
  
    private initializeDimensions(): void {
      this.myHeight = window.innerHeight;
      this.myWidth = window.innerWidth;
    }
    
    private subscribeToPollingSuccess(): void {
      this.pollingSuccess.subscribe(() => this.continueToNextStep());
    }
    // TERMINA SETUP


  initializeYears() {
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 15; i++) {
      const year = currentYear + i;
      this.years.push({ desc: year.toString(), value: year.toString().slice(-2) });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  create() {
    const obj = this.form.value;

    this.loader.open();
 // Limpieza del número de tarjeta de crédito antes de enviar
 obj.numberCreditCard = obj.numberCreditCard.replace(/[^0-9]/g, '');
    if (this.verifyCCYearAndMonth(obj.expMonthCreditCard.toString(), obj.expYearCreditCard.toString())) {
      if (obj.numberCreditCard.toString().length >= 13 && obj.numberCreditCard.toString().length <= 19) {
        obj.estatus = "1";
        obj.numberCreditCard = obj.numberCreditCard.toString();

        // let found = false;
        // for (let tdc of this.items) {
        //   if (tdc.maskCreditCard.substr(tdc.maskCreditCard.length - 4) == obj.numberCreditCard.toString().substr(obj.numberCreditCard.toString().length - 4)) {
        //     found = true;
        //   }
        // }


        const send = {
          param: 11,
          creditcard: obj,
          customer: this.auth.getCustomer()
        };
        //console.log("CON ESTE OBJETO PROCESAMOS LA TARJETA: ", send)
        this.loader.open();
        this.webService.suscribe(send, `${this.webService.HOST}/suscription`).subscribe(response => {
          this.loader.close();
          this.handleResponse(response)
        }, err => {
          this.loader.close();
          //console.log("ENTRO POR ESTE ERROR")
          this.notification.showError(err.message ?? err);
        });

        // if (!found) {
        //   this.webService.post(obj, this.webService.HOST + '/customer/' + this.auth.getCustomer() + '/creditcard').subscribe(params => {
        //     this.notification.showSuccess("Creada correctamente");
        //     this.loader.close();
        //     this.closeDialog();
        //   }, err => {
        //     if (err == "Credit card provider doesn't exist") {
        //       this.loader.close();
        //       this.notification.showError("Proveedor de la tarjeta de crédito desconocido");
        //     } else {
        //       this.loader.close();
        //       this.notification.showError("No se pudo crear tarjeta");
        //     }
        //   });
        // } else {
        //   this.loader.close();
        //   this.notification.showError("La tarjeta ya se encuentra registrada.");
        // }
      } else {
        this.loader.close();
        this.notification.showError("Verifique el número de la tarjeta. Cantidad de digitos inválida");
      }
    } else {
      this.loader.close();
      this.notification.showError("Verifique el año/mes de vencimiento de la tarjeta introducida");
    }
  }

  verifyCCYearAndMonth(month: string, year: string): boolean {
    if (Number.parseInt(month) < 1 || Number.parseInt(month) > 12) {
      return false;
    }

    if ((Number.parseInt(month) - 1) < new Date().getMonth()) {
      if (Number.parseInt(year) <= Number.parseInt(new Date().getFullYear().toString().substr(2))) {
        return false;
      }
    } else {
      if (Number.parseInt(year) < Number.parseInt(new Date().getFullYear().toString().substr(2))) {
        return false;
      }
    }

    if (Number.parseInt(year) >= (Number.parseInt(new Date().getFullYear().toString().substr(2)) + 15)) {
      return false;
    }
    return true;
  }

  onlyNumberKey(event: any) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  myLoadEvent() {
    this.loader.open();
    //console.log("CONSULTANDO: " + this.webService.HOST + "/creditcard/" + this.creditCardId)
    this.webService.get(this.webService.HOST + "/creditcard/" + this.creditCardId).subscribe(chp => {
      this.loader.close();
      if (chp.result.verified == true) { // ESTO ES QUE SE COBRO Y TODO BIEN CON LA SUSCRIPCION ESTA ACTIVA
        this.notification.showSuccess("Tarjeta agregada correctamente");
        timer(3000).subscribe(() => {
          this.closeDialog();
        });
      } else {
        this.notification.showError("Tarjeta rechazada por el banco, por favor ingrese otra");
      }
    }, err => {
      this.loader.close();
      this.notification.showError("Tarjeta rechazada por el banco, por favor ingrese otra");
    });
  }

    // 3DS DINAMICO
    private handleResponse(response: any): void {
      //console.log("RESPONSE DEL PROCESO 3DS: ", response)
      this.creditCardId = response.id;
  
      if (response.bank === 'powerTranz') {
        //console.log("POWERTRANZ")
        this.creditCardId = response.id;
        this.handlePowerTranzResponse(response);
      } else if (response.bank === 'cyberSource') {
        //console.log("CYBERSOURCE")
        this.handleCyberSourceResponse(response);
      } else if (response.bank === 'emetec') {
        //console.log("EMETEC")
        this.handleEmetecResponse(response);
      } else {
        if(response.message == "Successfull created"){ // LA TARJETA PUEDE ESTAR VALIDA PARA 3DS
          this.creditCardId = response.result;
          this.myLoadEvent();
        }else{
          this.notification.showError("ESTE BANCO NO ESTA REGISTRADO");
        }
      }
    }
    // TERMINA PROCESO DE 3DS DINAMICO
    // PROCESO PARA PROWERTRANZ
    private handlePowerTranzResponse(response: any): void {
      this.loader.close();
      this.openFacPopup(response.htmlFormData);
    }
  
    private openFacPopup(htmlFormData: string): void {
      //console.log('entre');
  
      // Mostrar spinner por 4 segundos
      this.showLoader(); // Abre el loader por encima de todo
      //console.log('lod',this.showLoader);
      // Añadir el fondo al body
      const backdrop = document.createElement('div');
      backdrop.className = 'custom-backdrop';
      document.body.appendChild(backdrop);
  
      const dialogRef = this.dialog.open(FacPopupComponent, {
          width: '600px',
          disableClose: true,
          data: { form: this.sanitizer.bypassSecurityTrustHtml(htmlFormData), creditCardId: this.creditCardId },
          panelClass: 'custom-dialog-container'
      });
  
      dialogRef.afterClosed().subscribe(() => {
          this.myLoadEvent();
          document.body.removeChild(backdrop); // Remover el fondo después de cerrar el popup
      });
  }
  
  // Método para mostrar el loader con z-index alto
  private showLoader(): void {
      // Establecer estilos para el loader
      const loaderElement = document.createElement('div');
      loaderElement.className = 'custom-loader'; // Clase CSS personalizada para el loader
      loaderElement.style.position = 'fixed';
      loaderElement.style.top = '50%';
      loaderElement.style.left = '50%';
      loaderElement.style.transform = 'translate(-50%, -50%)';
      loaderElement.style.zIndex = '999999'; // Z-index alto para estar por encima de todo
      loaderElement.style.backgroundColor = 'rgba(255, 255, 255, 0.8)'; // Fondo semi-transparente
      loaderElement.innerHTML = `
          <div class="spinner"> </div>
      `;
      document.body.appendChild(loaderElement);
  
      // Cerrar el spinner después de 4 segundos
      setTimeout(() => {
          document.body.removeChild(loaderElement);
      }, 8000);
  }
  
    // TERMINA PROCESO PARA POWERTRANZ

    // PROCESO PARA CYBERSOURCE
  private handleCyberSourceResponse(response: any): void {
    this.loader.open();
    if (response.sopResponse.responseCodeDescription !== "COMPLETED") {
      this.loader.close();
      this.notification.showError("ERROR - PAYER AUTH DISTINTO DE COMPLETE");
      return;
    }

    const transactionIdentifier = response.sopResponse.transactionId;
    const jwtToken = response.sopResponse.auth3DSDetail.token;
    this.uniqueCodeService.setTransactionIdentifier(transactionIdentifier);
    if (environment.production) {
      this.createAndSubmitIframeForm(
        jwtToken, 
        "https://centinelapi.cardinalcommerce.com/V1/Cruise/Collect", 
        "ddc-iframe", 
        "ddc-form"
      );
    } else {
      this.createAndSubmitIframeForm(
        jwtToken, 
        "https://centinelapistag.cardinalcommerce.com/V1/Cruise/Collect", 
        "ddc-iframe", 
        "ddc-form"
      );
    }

    setTimeout(() => {
      const updatedData = this.transactionenrollment();
      const json = { creditCardId : response.id, sop: updatedData, call: "enrollment" };
      //console.log("CON ESTO HACEMOS EL ENROLLMENT: ",  json)
      this.webService.post(json, `${this.webService.HOST}/sop?isAddingTdc=true`).subscribe(enrollment => {
        this.handleEnrollmentResponse(enrollment);
      }, err => {
        this.loader.close()
        console.error("ERROR LLAMANDO A ENRONLLMENT: ", err);
      });
    }, 10000);
  }

  private handleEnrollmentResponse(enrollment: any): void {
    //console.log("RESPUESTA DEL ENROLLMENT: ", enrollment);
  
    switch (enrollment.responseCodeDescription) {
      case "AUTHENTICATION_SUCCESSFUL":
        //console.log("Successful Frictionless Authentication");
        this.handleSuccess(enrollment);
        break;
  
      case "AUTHENTICATION_FAILED":
        //console.log("Unsuccessful Frictionless Authentication");
        this.loader.close();
        this.notification.showError("AUTHENTICATION_FAILED");
        this.deleteCreditCard();
        break;
  
      case "PENDING_AUTHENTICATION":
        //console.log("Pending Authentication - Step-Up Required");
        this.handlePendingAuthentication(enrollment);
        break;
  
      default:
        //console.log("Unexpected response code from enrollment");
        this.loader.close();
        this.notification.showError("Unexpected response code from enrollment : " + enrollment.responseCodeDescription );
        break;
    }
  }
  private handlePendingAuthentication(secondResponse: any): void {
    this.loader.open()
    //console.log('SE REALIZA PROCESO DE 3DS YA QUE ENROLLMENT RETORNO PENDING_AUTHENTICATION');
    this.startPolling();

    this.jwtTokenForThird = secondResponse.auth3DSDetail.htmlCode;
    this.transactionIdForThird = secondResponse.transactionId;
    //console.log('htmlCode para el 3DS:', this.jwtTokenForThird);

    if (secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "C") {
      //console.log("Successful Step-Up Authentication");
    }

    this.createAndSubmitStepUpForm(this.jwtTokenForThird);
  }

  
  createAndSubmitStepUpForm(jwtToken: string): void {
    // Crear el overlay
    const overlay = document.createElement('div');
    overlay.id = 'step-up-overlay';
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100vw';
    overlay.style.height = '100vh';
    overlay.style.background = 'rgba(0, 0, 0, 0.5)'; // Fondo oscuro semi-transparente
    overlay.style.zIndex = '99998'; // Debe estar por debajo del iframe
    overlay.style.pointerEvents = 'none'
    document.body.appendChild(overlay);

    // Crear el iframe
    const iframe = document.createElement('iframe');
    iframe.name = 'step-up-iframe';
    iframe.height = '900';
    iframe.width = '900';
    iframe.style.display = 'block';
    iframe.style.position = 'fixed';
    iframe.style.top = '50%';
    iframe.style.left = '50%';
    iframe.style.transform = 'translate(-50%, -50%)';
    iframe.style.zIndex = '99999';
    iframe.style.background = 'white';
    document.body.appendChild(iframe);
    this.iframeElement = iframe;

    // Crear y configurar el formulario
    const form = document.createElement('form');
    form.id = 'step-up-form';
    form.target = 'step-up-iframe';
    form.method = 'post';
    form.action = environment.production 
        ? 'https://centinelapi.cardinalcommerce.com/V2/Cruise/StepUp'
        : 'https://centinelapistag.cardinalcommerce.com/V2/Cruise/StepUp';

    const inputJWT = document.createElement('input');
    inputJWT.type = 'hidden';
    inputJWT.name = 'JWT';
    inputJWT.value = jwtToken;
    form.appendChild(inputJWT);

    const inputMD = document.createElement('input');
    inputMD.type = 'hidden';
    inputMD.name = 'MD';
    inputMD.value = 'optionally_include_custom_data_that_will_be_returned_as_is';
    form.appendChild(inputMD);

    document.body.appendChild(form);
    form.submit();

    iframe.onload = () => {
        console.log('Iframe StepUp loaded and displayed.');
    };

    // Agregar evento de cierre al hacer clic en el overlay
    overlay.addEventListener('click', () => {
        document.body.removeChild(overlay);
        document.body.removeChild(iframe);
    });

    this.loader.close();

    const expectedOrigin = environment.production
        ? "https://centinelapi.cardinalcommerce.com"
        : "https://centinelapistag.cardinalcommerce.com";

    window.addEventListener("message", (event) => {
        if (event.origin === expectedOrigin) {
            const data = JSON.parse(event.data);
            console.log('Mensaje StepUp recibido:', data);
        }
    }, false);
}


  private transactionenrollment(additionalData: any = {}): any {
    return {
      creditCard: {
        cardholderName: this.form.value.nameHolderCreditCard,
        creditCardDate: this.form.value.expYearCreditCard + this.form.value.expMonthCreditCard,
        cvv: this.form.value.ccv
      },
      transactionIdentifier: this.uniqueCodeService.getTransactionIdentifier(),
      currency: "USD",
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email: localStorage.getItem('username'),
        ...additionalData
      },
      auth: additionalData.auth
    };
  }

  startPolling(): void {
    this.loader.open();
    const url = `${this.webService.HOST + "/creditcard/" + this.creditCardId}`;
    let polling = true;
  
    interval(3000).pipe(
      takeWhile(() => polling),
      switchMap(() => this.webService.get(url).pipe(
        catchError(error => {
          if (error.status === 404) {
            this.loader.close();
            //console.log('Respuesta 404: No encontrado');
            return of(null);
          } else {
            this.loader.close();
            console.error('Error en la solicitud:', error);
            return of(null);
          }
        })
      ))
    ).subscribe(response => {
      if (response && response?.result) {
        //console.log("RESPUESTA", response);
  
        if (response.result.status == 1) {
          this.loader.close();
          polling = false;
          this.pollingSuccess.next();
          this.removeIframe();
        }
      }
    });
  }

  private handleSuccess(secondResponse: any): void {
    // ESTE CASO ES FRICTION LESS POR LO TANTO APROBAMOS LA TDC
    this.loader.open();
    //console.log("HACEMOS EL authorize3DS")
    this.webService.post({}, `${this.webService.HOST}/authorize3DS/${this.creditCardId}/response/12?suscription=true`).subscribe(fourthResponse => {
      //console.log("RESPUESTA DEL authorize3DS: ", fourthResponse)
      this.myLoadEvent();
      this.loader.close();
    }, errr =>{
      //console.log("ENTRO POR ESTE OTRO ERROR")
      console.error(errr);
      this.notification.showError(errr)
    });
  }

  private deleteCreditCard(){
    this.loader.open();
    //console.log("HACEMOS EL DELETE DE LA TARJETA con esta URL : " + `${this.webService.HOST}/customer/${this.auth.getCustomer()}/creditcard/${this.creditCardId}/delete?useIdentifier=true` )
    this.webService.post({}, `${this.webService.HOST}/customer/${this.auth.getCustomer()}/creditcard/${this.creditCardId}/delete?useIdentifier=true`).subscribe(fourthResponse => {
      //console.log("RESPUESTA DEL DELETE: ", fourthResponse)
      this.myLoadEvent();
      this.loader.close();
    }, errr =>{
      //console.log("ERROR EN EL DELETE: ", errr)
      this.notification.showError(errr)
      this.loader.close();
    });
  }


  private createAndSubmitIframeForm(jwtToken: string, actionUrl: string, iframeName: string, formId: string): void {
    const iframe = document.createElement('iframe');
    iframe.name = iframeName;
    iframe.height = '900';
    iframe.width = '900';
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    this.iframeElement = iframe;

    const form = document.createElement('form');
    form.id = formId;
    form.target = iframeName;
    form.method = 'POST';
    form.action = actionUrl;

    const inputJWT = document.createElement('input');
    inputJWT.type = 'hidden';
    inputJWT.name = 'JWT';
    inputJWT.value = jwtToken;
    form.appendChild(inputJWT);

    document.body.appendChild(form);
    form.submit();

    iframe.onload = () => {
      iframe.style.display = 'block';
    };
    const expectedOrigin = environment.production
    ? "https://centinelapi.cardinalcommerce.com"  // Producción
    : "https://centinelapistag.cardinalcommerce.com";  // Desarrollo
    window.addEventListener("message", (event) => {
      if (event.origin === expectedOrigin) {
        const data = JSON.parse(event.data);
        //console.log('Mensaje recibido:', data);
      }
    }, false);
  }

  private removeIframe(): void {
    if (this.iframeElement) {
      this.loader.close();
      document.body.removeChild(this.iframeElement);
    }
  }

  continueToNextStep() {
    this.loader.open();
  
    const transactionIdentifier = this.uniqueCodeService.getTransactionIdentifier();
  
    if (!this.jwtTokenForThird || !this.transactionIdForThird) {
      console.error('No se encontró jwtTokenForThird o transactionIdForThird para la tercera petición.');
      this.loader.close();
      return;
    }
  
    const thirdData = this.createTransactionData(transactionIdentifier, {
      auth: {
        authenticationTransactionId: this.transactionIdForThird,
        signedPares: this.jwtTokenForThird
      }
    });
  
    const validateJson = { creditCardId: this.creditCardId, sop: thirdData, call: "validate" };
    //console.log('REQUEST PARA VALIDATE AUTH', validateJson);
  
    this.webService.post(validateJson, this.webService.HOST + "/sop?isAddingTdc=true")
      .pipe(
        switchMap(thirdResponse => this.handleValidateAuthResponse(thirdResponse, transactionIdentifier)),
        catchError(error => this.handleErrorDuringChain(error))
      )
      .subscribe(fourthResponse => this.handleSaleResponse(fourthResponse));
  }
  
  private handleValidateAuthResponse(thirdResponse: any, transactionIdentifier: string): Observable<any> {
    this.loader.open();
    //console.log('Respuesta para VALIDATE AUTH:', thirdResponse);
  
    if (thirdResponse?.responseCodeDescription === "AUTHENTICATION_SUCCESSFUL") {
      //console.log("Successful Step-Up Authentication ON VALIDATE AUTH");
      //console.log("HACEMOS EL authorize3DS / AUTENTICAMOS LA TARJETA")
      return this.webService.post({}, `${this.webService.HOST}/authorize3DS/${this.creditCardId}/response/12?suscription=true`);
    } else {
      //console.log("VALIDATE AUTH RETORNO AUTHENTICATION_FAILED");
      this.notification.showError("AUTHENTICATION_FAILED");
      this.deleteCreditCard(); // ELIMINAMOS LA TARJETA PORQUE NO SE PUDO AUTENTICAR
      this.loader.close();
      return of(null);
    }
  }
  
  private handleErrorDuringChain(error: any): Observable<null> {
    console.error('Error en la cadena de peticiones:', error);
    this.notification.showError(`Error en VALIDATE AUTH: ${error.message}`);
    this.loader.close();
    return of(null);
  }
  
  private handleSaleResponse(fourthResponse: any): void {
    this.loader.open();
  
    this.myLoadEvent();
  
    this.loader.close();
  }

  createTransactionData(transactionIdentifier: string = '', additionalData: any = {}): any {
    return {
      creditCard: {
        cardholderName: this.form.value.nameHolderCreditCard,
        creditCardDate: `${this.form.value.expYearCreditCard}${this.form.value.expMonthCreditCard}`,
        cvv: this.form.value.ccv
      },
      transactionIdentifier: transactionIdentifier,
      currency: "USD",
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email: localStorage.getItem('username'),
        street: this.form.value.street,
        city: this.form.value.city,
        postalCode: this.form.value.postalCode,
        phone: this.form.value.phone,
        ...additionalData
      },
      auth: additionalData.auth
    };
  }

  // TERMINA PROCESOS CYBERSOURCE
// EMETEC PROCESOS
private handleEmetecResponse(response: any): void {
  //console.log("Iniciando manejo de respuesta de Emetec:", response);

  this.dataObject = this.buildRequestData();
  console.log("Datos del objeto construido con tarjeta de crédito:", this.dataObject.creditcard);

  if (!response.sopResponse || !response.sopResponse.responseCodeDescription) {
    console.error("Respuesta de Emetec inválida. Faltan datos.");
    this.notification.showError("Respuesta de Emetec inválida. Faltan datos.");
    return;
  }

  if (response.sopResponse.responseCodeDescription === "transaction pending") {
    this.handleEmetecPendingTransaction(response);
    console.log("Transacción pendiente. Procesando...");
    console.log(response);
  } else {
    console.log("Procesando transacción...");
    const updatedData = this.transactionEmetec();
    console.log("Datos de la transacción de Emetec (preparados para usar):", updatedData);

    updatedData.transactionIdentifier = response.sopResponse.transactionId;

    if (!updatedData.transactionIdentifier) {
      console.error("Identificador de transacción no proporcionado.");
      this.notification.showError("Identificador de transacción no proporcionado.");
      return;
    }

    //console.log("Datos de tarjeta de crédito antes del pago:", this.dataObject.creditcard);
    console.log('Valor de chp antes de getEmetecPayment: ', this.chp);
    const json = { creditCardId : response.id, sop: updatedData, call: "payment"};
    const voidJson = { creditCardId : response.id, sop: updatedData, call: "void"};
    this.refundJson = { creditCardId : response.id, sop: updatedData, call: "refund" };
    //console.log("JSON para el pago (contiene tarjeta de crédito):", json);
    this.getEmetecPayment(json, voidJson);
  }
}

getEmetecPayment(json: any, voidJson: any): void {
  // console.log('Número de tarjeta de crédito antes del envío:', this.form.value.numberCreditCard);
  // console.log('expMonthCreditCard:', this.form.value.expMonthCreditCard);
  // console.log('expYearCreditCard:', this.form.value.expYearCreditCard);
  // console.log('ccv:', this.form.value.ccv);
  //  // Verificar los datos del JSON que se enviarán a la API SOP
  //  console.log('Datos JSON que se enviarán a SOP:', json);
  //  console.log('Datos VOID JSON que se enviarán a SOP:', voidJson);
  // if (!json || !json.chp || !json.sop || !json.sop.creditcard || !json.sop.transactionIdentifier) {
  //   console.error('Datos incompletos o inválidos para la solicitud de pago a Emetec.');
  //   console.log('Estado de json: ', json);
  //   console.log('Estado de json.chp: ', json?.chp);
  //   console.log('Estado de json.sop: ', json?.sop);
  //   console.log('Estado de json.sop.creditcard: ', json?.sop?.creditcard);
  //   console.log('Estado de json.sop.transactionIdentifier: ', json?.sop?.transactionIdentifier);
  //   this.notification.showError('Datos incompletos para procesar la transacción.');
  //   return;
  // }

  // Verificar si el objeto creditcard existe y tiene el número de tarjeta antes de cualquier otra validación
  const { creditcard } = json.sop;

  console.log("Contenido de creditcard: ", creditcard); // <-- Agregamos este log para verificar el contenido completo de creditcard
  
  if (!creditcard || !creditcard.numberCreditCard) {
    console.error('Número de tarjeta de crédito no definido.');
    this.notification.showError('Número de tarjeta de crédito no definido.');
    return;
  }

  // Limpieza del número de tarjeta de crédito (por si tiene espacios en blanco)
  creditcard.numberCreditCard = creditcard.numberCreditCard.replace(/\s+/g, ''); // Eliminar espacios

  // Verificar la longitud del número de tarjeta
  console.log(`Número de tarjeta: ${creditcard.numberCreditCard}, Longitud: ${creditcard.numberCreditCard.length}`);

  if (creditcard.numberCreditCard.length !== 16) {
    console.error('Número de tarjeta de crédito inválido.');
    this.notification.showError('Número de tarjeta de crédito inválido.');
    return;
  }

 

  this.loader.open(); 
  // Proceder con la lógica de la solicitud
  if (this.retryCount < this.maxRetries) {
    this.retryCount++;
    console.log('Datos enviados al servicio SOP:', json);

    console.log(`Intento ${this.retryCount} de ${this.maxRetries}. Enviando datos a ${this.webService.HOST}/sop?isAddingTdc=true`);
    timer(3000)
      .pipe(
        switchMap(() => {
          console.log("Datos enviados al servicio SOP:", json);
          return this.webService.post(json, `${this.webService.HOST}/sop?isAddingTdc=true`);
        }),
        takeWhile(() => this.retryCount <= this.maxRetries)
      )
      .subscribe(
        (response) => {
          console.log("Respuesta recibida del servicio SOP:", response);
          if (response.responseCodeDescription === "Transaction succeeded") {
            console.log("Transacción exitosa.");
            this.loader.open();
            this.myLoadEvent();
            this.emetecVoid(voidJson);
          } else if (response.responseCodeDescription === "transaction pending") {
            console.log("Transacción pendiente. Reintentando...");
            this.getEmetecPayment(json, voidJson);
          } else {
            this.emetecVoid(voidJson);
            console.error("Error en la transacción:", response.responseCodeDescription);
            this.loader.close();
            this.notification.showError("Hubo un error en el proceso: " + response.responseCodeDescription);
            this.deleteCreditCard();
          }
        },
        (err) => {
          console.error("ERROR durante la solicitud:", err);
          this.loader.close();
          this.notification.showError("Error en el proceso de pago. Intente nuevamente.");
        }
      );
  } else {
    console.warn("Número máximo de intentos alcanzado.");
    this.loader.close();
    this.notification.showError(
      "Se alcanzó el número máximo de intentos. Transacción sigue en estatus pending. Contacte con el administrador."
    );
    this.emetecVoid(voidJson);
    this.deleteCreditCard(); 
  }

}




emetecVoid(json: any): void {


  console.log("CON ESTO BUSCAMOS HACER EL VOID: ", JSON.stringify(json, null, 2));
  this.webService.post(json, `${this.webService.HOST}/sop?isAddingTdc=true`).subscribe(
    (response) => {
      console.log("VOID EXITOSO");
      this.loader.close();
      // this.myLoadEvent(); // Confirmación del evento final después de void exitoso
    },
    (err) => {
      console.error("Error realizando VOID:", err);
      this.loader.close();
      // this.notification.showError(
      //   "Error realizando VOID de la transaccion, por favor contacte con el administrador"
      // );
      this.emetecRefund(); // COMO EL VOID DIO ERROR INTENTAMOS CON EL REFUND
    }
  );
}

emetecRefund() : void {
  this.webService.post(this.refundJson, `${this.webService.HOST}/sop?isAddingTdc=true`).subscribe(
    (response) => {
      console.log("REFUND EXITOSO");
      this.loader.close();
      // this.myLoadEvent(); // Confirmación del evento final después de void exitoso
    },
    (err) => {
      console.error("Error realizando VOID:", err);
      this.loader.close();
      this.notification.showError(
        "Error realizando REFUND de la transaccion, por favor contacte con el administrador"
      );
    }
  );
}

private handleEmetecPendingTransaction(response: any): void {
  this.loader.open();
  // Almacena los detalles de la transacción desde la respuesta
  this.transactionId = response.sopResponse.transactionId;
  this.iframeValue = response.sopResponse.auth3DSDetail.iframeValue;
  this.iframeName = response.sopResponse.auth3DSDetail.iframeName;
  this.iframeUrl = response.sopResponse.auth3DSDetail.iframeUrl;
  this.redirectUrl = response.sopResponse.auth3DSDetail.redirectUrl;

  //console.log("Datos almacenados:");
  //console.log("transactionId:", this.transactionId);
  //console.log("iframeValue:", this.iframeValue);
  //console.log("iframeName:", this.iframeName);
  //console.log("iframeUrl:", this.iframeUrl);
  //console.log("redirectUrl:", this.redirectUrl);

  // Envía solicitud POST usando fetch con no-cors para el primer iframe
  this.sendIframeRequest(this.iframeUrl, this.iframeValue)
    .then(() => {
      //console.log("Solicitud del primer iframe enviada con éxito");
      this.loader.close(); // Detiene el loader antes de abrir el popup

      // Abre el segundo iframe en un popup
      this.openInteractiveIframePopup(this.redirectUrl, this.iframeValue, response.sopResponse.transactionId);
    })
    .catch((err) => {
      console.error("Error al enviar la solicitud del primer iframe:", err);
      this.notification.showError("Error al enviar la solicitud del primer iframe:" + err);
      this.loader.close();
    });
}

openInteractiveIframePopup(url: string, iframeValue: string, transactionID: string): void {
  // Abre el diálogo utilizando AppDialogContainerComponent como contenedor
  const dialogRef = this.dialog.open(AppDialogContainerComponent, {
    width: '600px',
    panelClass: 'custom-dialog-zindex' // Aplica la clase CSS personalizada para el z-index si es necesario
  });

  // Crear el contenido del diálogo dinámicamente
  const iframeContainer = this.renderer.createElement('div');
  const iframe = this.renderer.createElement('iframe');
  const spinner = this.renderer.createElement('div'); // Spinner del loader

  // Configurar elementos del iframe
  this.renderer.setAttribute(iframe, 'src', url);
  this.renderer.setAttribute(iframe, 'width', '100%');
  this.renderer.setAttribute(iframe, 'height', '400px');
  this.renderer.setAttribute(iframe, 'sandbox', 'allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts');
 //spiner connf
  this.renderer.addClass(spinner, 'spinner');
  this.renderer.setStyle(spinner, 'position', 'absolute');
  this.renderer.setStyle(spinner, 'top', '50%');
  this.renderer.setStyle(spinner, 'left', '45%');
  this.renderer.setStyle(spinner, 'transform', 'translate(-50%, -50%)');
  this.renderer.setStyle(spinner, 'z-index', '100000'); // Asegura que el spinner esté por encima del iframe

  // Añadir elementos al contenedor del diálogo
  this.renderer.appendChild(iframeContainer, spinner); // Añadir el spinner al contenedor
  this.renderer.appendChild(iframeContainer, iframe); // Añadir el iframe después del spinner


  dialogRef.afterOpened().subscribe(() => {
    const dialogComponentInstance = dialogRef.componentInstance;
    if (dialogComponentInstance.dialogContent) {
      this.renderer.appendChild(dialogComponentInstance.dialogContent.nativeElement, iframeContainer);
    }
  });

  // Ocultar el spinner cuando el iframe haya terminado de cargar
  this.renderer.listen(iframe, 'load', () => {
    this.renderer.setStyle(spinner, 'display', 'none');
  });

  // Configurar los datos relevantes
  this.dataObject.transactionIdentifier = transactionID;

  // Añadir listener para recibir mensajes del iframe
  window.addEventListener('message', (event) => this.handleIframeMessage(event, dialogRef), false);
}

/**
 * Maneja los mensajes postMessage recibidos desde el iframe de cloud.billcentrix.com.
 * @param event 
 * @param dialogRef 
 */
private handleIframeMessage(event: MessageEvent, dialogRef: MatDialogRef<any>): void {

  let trustedOrigin = '';

  // Configura la URL confiable según el environment
  if (environment.production) {
    trustedOrigin = "https://cloud.billcentrix.com"; // URL de producción
  } else {
    trustedOrigin = "https://dev-onboarding-new.billcentric.com"; // URL de desarrollo
  }


  // Validar el origen del mensaje
  if (event.origin !== trustedOrigin) {
    console.warn('Mensaje recibido de un origen no confiable:', event.origin);
    return;
  }

  // Procesar el mensaje
  const { status, url } = event.data;
  if (status === 'loaded') {
    //console.log(El iframe ha cargado con la URL: ${url});
    
    // Cerrar el loader si estaba abierto
    this.loader.close();
  
    // Cerrar el diálogo después de recibir la respuesta de cloud.billcentrix.com
    this.onIframeLoadedSuccessfully(dialogRef);
  }
}

/**
 * 
 * @param dialogRef Referencia al diálogo que se cerrará.
 */
private onIframeLoadedSuccessfully(dialogRef: MatDialogRef<any>): void {
  console.log('Iframe cargado exitosamente, realizando acciones adicionales...');
  
  // Cerrar el diálogo
  dialogRef.close();
  
  // Verificar los datos antes de proceder con la llamada a getEmetecPayment
  console.log('Datos de this.dataObject antes del envío:', this.dataObject);
  
  const json = { creditCardId: this.creditCardId, sop: this.dataObject, call: "payment"  };
  const voidJson = { creditCardId: this.creditCardId, sop: this.dataObject, call: "void"  };
  this.refundJson = { creditCardId : this.creditCardId, sop: this.dataObject, call: "refund" };
  
  // Verificar los datos que se enviarán a getEmetecPayment
  console.log('Datos para la solicitud de pago (json):', json);
  console.log('Datos para la solicitud de anulación (voidJson):', voidJson);
  
  // Llamada corregida para el pago de Emetec
  this.getEmetecPayment(json, voidJson); 
}


private buildRequestData(): any {
  //console.log("Construyendo datos de la solicitud de pago...");
  
  const baseData = {
    creditcard: {
      numberCreditCard: this.form.value.numberCreditCard.replace(/[^0-9]/g, ''),
      nameHolderCreditCard: this.form.value.nameHolderCreditCard.trim(),
      expMonthCreditCard: this.form.value.expMonthCreditCard.trim(),
      expYearCreditCard: this.form.value.expYearCreditCard.trim(),
      ccv: this.form.value.ccv.trim()
    },
    extraData: {
      ip: this.myIp,
      fingerprint: this.uniqueCode,
      screenHeight: this.myHeight,
      screenWidth: this.myWidth,
      email: localStorage.getItem("username"),
      street: this.form.value.street.trim(),
      city: this.form.value.city.trim(),
      postalCode: this.form.value.postalCode.trim(),
      phone: this.form.value.phone.trim()
    }
  };

  //console.log("Datos de la tarjeta de crédito:", baseData.creditcard);
  //console.log("Datos adicionales:", baseData.extraData);

  return baseData;
}

async sendIframeRequest(url: string, iframeValue: string): Promise<void> {
  // Create the request body in URL-encoded format
  const body = new URLSearchParams();
  body.append("threeDSMethodData", iframeValue);

  //console.log("Datos que se envían al iframe:");
  //console.log("URL:", url);
  //console.log("Datos del formulario:", body.toString());

  // Send the POST request to the iframeUrl
  try {
    let response: Response;
    let attempts = 0;
    const maxAttempts = 5;
    const delay = 1000; // 1 second

    do {
      response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: body.toString(),
        mode: "no-cors", // Add no-cors mode
        credentials: "include",
      });

      if (response.status === 200) {
        //console.log("Solicitud iframe enviada con éxito.");
        break;
      } else {
        console.error(`Intento ${attempts + 1}: Error en la solicitud iframe:`, response.status, response.statusText);
      }

      attempts++;
      if (attempts < maxAttempts) {
        await new Promise((resolve) => setTimeout(resolve, delay)); // Wait 1 second before the next attempt
      }
    } while (attempts < maxAttempts);

    if (attempts === maxAttempts && response.status !== 200) {
      console.error("Se alcanzó el número máximo de intentos y la solicitud no fue exitosa.");
    }
  } catch (error) {
    console.error("Error al enviar la solicitud del primer iframe:", error);
  }
}

private transactionEmetec(): any {
  return {
    creditcard: {
      numberCreditCard: this.form.value.numberCreditCard.replace(/\s+/g, ''),
      cardholderName: this.form.value.nameHolderCreditCard,
      creditCardDate: this.form.value.expYearCreditCard + this.form.value.expMonthCreditCard,
      expYearCreditCard:this.form.value.expYearCreditCard,
      expMonthCreditCard:this.form.value.expMonthCreditCard,

      cvv: this.form.value.ccv,
    },
    transactionIdentifier: this.uniqueCodeService.getTransactionIdentifier(),
    currency: "USD",
    extraData: {
      ip: this.myIp,
      fingerprint: this.uniqueCode,
      screenHeight: this.myHeight,
      screenWidth: this.myWidth,
      email: localStorage.getItem("username"),
      street: this.form.value.street,
      city: this.form.value.city,
      postalCode: this.form.value.postalCode,
      phone: this.form.value.phone,
    },
  };
}
}