import { ChangeDetectorRef,Renderer2,Component, Inject, OnInit } from '@angular/core';
import { Plan } from '../components/plan/model/plan.model';
import { ThemeService } from '../theme.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../services/auth/auth.service';
import { ImagesService } from '../services/image/images.service';
import { PlanService } from '../services/plan/plan.service';
import { WebService } from '../services/web.service';
import { NotificationService } from '../services/notification.service';
import { LoaderService } from '../services/loader.service';
import { LoginService } from '../services/login/login.service';
import { FacPopupComponent } from '../components/fac-popup/fac-popup.component';
import { LoginPopupComponent } from '../login/login-popup/login-popup.component';
import { MisdireccionesComponent } from './misdirecciones/misdirecciones.component';
import { MistarjetasComponent } from './mistarjetas/mistarjetas.component';
import { Router, ActivatedRoute } from '@angular/router';
import { interval, Observable, of, timer,Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { catchError, map, switchMap, delay,takeWhile  } from 'rxjs/operators';
import { UniqueCodeService } from './unique-code.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppDialogContainerComponent } from '../checkout/app-dialog-container/app-dialog-container.component'; // Importa tu componente aquí
import { environment } from '../../environments/environment';
import { LanguageService } from '../services/language.service';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  isAutoFilling: boolean = false; // Nueva bandera para ignorar cambios automáticos
  expirationYears: { value: string; label: string }[] = [];

      isManualInput: boolean = true; 
      public selectedPlan: Plan;
      planImage: any;
      plan: any;
      showMenu: Boolean = true;
      provinces: any[] = [];
      form: any;
      corregimientos: any[] = [];
      addressID: any;
      addressIdSelected: any;
      tdcID: any;
      tdcStatus: any;
      tdcCorerID: any;
      tdc: string;
      hasPlanID: any;
      emailCustomer: any;
      confirmation: Boolean = false;
      isActive = true;
      isDialogOpen = false;
      isPayedPortal: Boolean = true;
      onboardingCongigID: any;
      partnerCorregimientos : any[]= [] ;
      // NECESARIOS PARA 3DS DINAMICO
      retryCount = 0;
      maxRetries = 5;
      uniqueCode: string = "";
      myIp: string = '189.127.166.102';
      myHeight: number;
      myWidth: number;
      jwtTokenForThird: string;
      transactionIdForThird: string;
      transactionId: string;
      iframeValue: string;
      iframeName: string;
      iframeUrl: string;
      redirectUrl: string;
      dataObject: any;
      private pollingSuccess = new Subject<void>();
      iframeElement: HTMLIFrameElement;
      chp : any;
      httpAcceptBrowserValue;
      httpAcceptContent ;
      httpBrowserColorDepth;
      httpBrowserJavaEnabled;
      httpBrowserJavaScriptEnabled;
      httpBrowserLanguage ;
      httpBrowserTimeDifference ;
  refundJson: { chp: any; sop: any; call: string; };

  // Almacenamiento interno de la fecha de hoy
  todayDate: string;
  calculatedDate: Date;
  private openLoader(): void {
    //console.log('Opening loader...'); // Ejemplo de registro adicional
    this.loader.open();
  }
  
  private closeLoader(): void {
    //console.log('Closing loader..'); // Ejemplo de registro adicional
    this.loader.close();
  }
  private generateExpirationYears(): void {
    const currentYear = new Date().getFullYear();
    const yearsToGenerate = 15; // Cantidad de años que deseas mostrar
    this.expirationYears = Array.from({ length: yearsToGenerate }, (_, i) => {
        const fullYear = currentYear + i;
        return { value: fullYear.toString().slice(-2), label: fullYear.toString() };
    });
}

  constructor(
    public languageService: LanguageService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private uniqueCodeService: UniqueCodeService,
    @Inject(DOCUMENT) private document: Document,private activateRoute: ActivatedRoute, private router: Router, public themeService: ThemeService, public loginService: LoginService, private sanitizer: DomSanitizer, private dialog: MatDialog, public auth: AuthService, private imageService: ImagesService, private planService: PlanService, private web: WebService, private notification: NotificationService, private loader: LoaderService) {
      this.initializeForm();
  }
    // SETUP PARA 3DS DINAMICO
    private initializeForm(): void {
      this.form = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        name: new FormControl('', Validators.required),
        lastname: new FormControl('', Validators.required),
        address: new FormControl('', Validators.required),
        name_tdc: new FormControl('', Validators.required),
        number_tdc: new FormControl('', [Validators.required, this.validateCreditCardNumber]), 
        month: new FormControl('', Validators.required),
        year: new FormControl('', Validators.required),
        ccv: new FormControl('', Validators.required),
        province: new FormControl('', Validators.required),
        corregimiento: new FormControl('', Validators.required),
        street: new FormControl('', [Validators.required]),
        city: new FormControl('', [Validators.required]),
        postalCode: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
        phone: new FormControl('', [Validators.required, Validators.pattern(/^\d{7,10}$/)])
      });
    }
    // 3DS DINAMICO
    ngAfterViewInit(): void {
      setTimeout(() => {
        const uniqueCode = this.generateUniqueCode();
        this.uniqueCodeService.setUniqueCode(uniqueCode);
        this.uniqueCode = uniqueCode;
        // this.addUniqueCodeToHead(uniqueCode);
        // this.addUniqueCodeToBody(uniqueCode);
        this.cdr.detectChanges();
      }, 0);
    }
    formatCreditCardNumber(): void {
      if (!this.isManualInput) return; // Salir si no es entrada manual
    
      const control = this.form.get('number_tdc');
      let value = control.value.replace(/[^0-9]/g, ''); // Eliminar caracteres no numéricos
    
      if (value.length > 16) {
        value = value.substring(0, 16); // Limitar a 16 caracteres
      }
    
      const formattedValue = value.match(/.{1,4}/g)?.join('-') || value;
      control.setValue(formattedValue, { emitEvent: false });
    }
    validateCreditCardNumber = (control: FormControl): { [key: string]: any } | null => {
      const value = control.value.replace(/-/g, ''); // Eliminar guiones para validación
    
      if (!value || !this.isManualInput) return null; // No aplicar validación si no es entrada manual
    
      // Comprobar si tiene 15 o 16 dígitos
      if (value.length !== 15 && value.length !== 16) {
        return { invalidLength: true };
      }
    
      // Validar usando el Algoritmo de Luhn
      if (!this.luhnCheck(value)) {
        return { invalidCardNumber: true };
      }
    
      return null;
    };
    // Algoritmo de Luhn para validar el número de tarjeta
    luhnCheck(cardNumber: string): boolean {
      let sum = 0;
      let shouldDouble = false;
  
      for (let i = cardNumber.length - 1; i >= 0; i--) {
        let digit = parseInt(cardNumber.charAt(i), 10);
  
        if (shouldDouble) {
          digit *= 2;
          if (digit > 9) digit -= 9;
        }
  
        sum += digit;
        shouldDouble = !shouldDouble;
      }
  
      return (sum % 10) === 0;
    }
    //fin nico
    onCreditCardInput(): void {
      this.isManualInput = true; // Activar validación cuando el usuario escribe manualmente
    }
    private addUniqueCodeToHead(uniqueCode: string,orgId: string, merchantId: string): void {
      
      const comment = this.renderer.createComment('script head nico');
      this.renderer.appendChild(this.document.head, comment);
      const script = this.renderer.createElement('script');
      this.renderer.setAttribute(script, 'type', 'text/javascript');
      this.renderer.setAttribute(script, 'src', `https://h.online-metrix.net/fp/tags.js?org_id=${environment.orgId}&session_id=${merchantId}${uniqueCode}`);
      this.renderer.appendChild(this.document.head, script);
      console.log('Añadiendo script al head:', script.src);
      script.onload = () => console.log('Script loaded successfully.', script);
      script.onerror = () => console.error('Failed to load the script.');
    }

    private addUniqueCodeToBody(uniqueCode: string,orgId: string, merchantId: string): void {
      const noscript = this.renderer.createElement('noscript');
      const iframe = this.renderer.createElement('iframe');
      this.renderer.setStyle(iframe, 'width', '100px');
      this.renderer.setStyle(iframe, 'height', '100px');
      this.renderer.setStyle(iframe, 'border', '0');
      this.renderer.setStyle(iframe, 'position', 'absolute');
      this.renderer.setStyle(iframe, 'top', '-5000px');
      console.log('Añadiendo iframe al body:', iframe.src);
      this.renderer.setAttribute(iframe, 'src', `https://h.online-metrix.net/fp/tags?org_id=${environment.orgId}&session_id=${merchantId}${uniqueCode}`);
      this.renderer.appendChild(noscript, iframe);
      this.renderer.appendChild(this.document.body, noscript);
    }
  
    private generateUniqueCode(): string {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }

    private initializeDimensions(): void {
      this.myHeight = window.innerHeight;
      this.myWidth = window.innerWidth;
      this.httpAcceptBrowserValue = navigator.userAgent;
      this.httpAcceptContent = navigator.userAgent; // Similar al anterior
      this.httpBrowserColorDepth = window.screen.colorDepth;
      this.httpBrowserJavaEnabled = true;
      this.httpBrowserJavaScriptEnabled = true; // Si el código se ejecuta, JavaScript está habilitado
      this.httpBrowserLanguage = navigator.language;
      this.httpBrowserTimeDifference = new Date().getTimezoneOffset().toString();
    }
    
    private subscribeToPollingSuccess(): void {
      this.pollingSuccess.subscribe(() => this.continueToNextStep());
    }
    
    private handleRouteParams(): void {
      this.activateRoute.params.subscribe({
        next: (params) => this.processRouteParams(params),
        error: (err) => this.handleRouteParamsError(err)
      });
    }
    
    private processRouteParams(params: any): void {
      console.log("Route params:", params);

      const param = params["id"] ?? -1;
        //this.web.get(this.web.HOST + "/onboardingConfig/bydomain/" + "nosenosenose.billcentric.com" + "/"  + -1).subscribe({
          
      // this.web.get(`${this.web.HOST}/onboardingConfig/bydomain/${window.location.host}/${param}`).subscribe({
      //this.web.get(this.web.HOST + "/onboardingConfig/bydomain/" + "nomostrarverportal.billcentrix.com" + "/"  + -1).subscribe({
      this.web.get(`${this.web.HOST}/onboardingConfig/bydomain/${window.location.host}/${param}`).subscribe({
      
      next: (response) => {
        // Log para ver la respuesta del servidor
        console.log("Response from server:", response);
        const orgId = response?.result?.partner?.bank?.orgId || null;
        const merchantId = response?.result?.partner?.bank?.merchantId || null;

        if(response?.result?.partner?.bank?.bank_identificator == "cyberSource"){
          if (!orgId || !merchantId) {
            console.error("orgId o merchantId no están presentes en la respuesta.");
            this.notification.showError("orgId o merchantId no están presentes en la respuesta.");
            return;
          }
        }

        
        this.handleWebResponse(response);
          // Usa los valores de orgId y merchantId
          this.addUniqueCodeToHead(this.uniqueCode, orgId, merchantId);
          this.addUniqueCodeToBody(this.uniqueCode, orgId, merchantId);
      },
        error: (err) => this.handleWebError(err)
      });
    }

    onCVVInputChange(): void {
      if (!this.isAutoFilling) {
        //console.log('El usuario ingresó el CVV, pero no se cambia a modo manual.');
      } else {
        //console.log('El CVV fue ingresado automáticamente.');
      }
    }
    
    
    private handleWebResponse(response: any): void {
      if (!response.result.customerHasPlan || response.result.customerHasPlan.status_customerPlan !== 1) {
        this.isPayedPortal = false;
      }
      this.onboardingCongigID = response.result.id;
      this.loadPartnerData();
    }
    
    private handleWebError(err: any): void {
     // this.loader.close();
      this.notification.showError(err);
    }
    
    private handleRouteParamsError(err: any): void {
     // this.loader.close();
      this.notification.showError(err);
    }

    ngOnInit(): void {
       this.uniqueCode = this.generateUniqueCode(); // Generar aquí
   console.log("Unique Code inicializado:", this.uniqueCode);
   this.generateExpirationYears();
   this.initializeDimensions();
   this.subscribeToPollingSuccess();
   this.handleRouteParams();
   this.listenToFormChanges();
      
    }
    
    private listenToFormChanges(): void {
      this.form.get('number_tdc')?.valueChanges.subscribe(() => {
        if (!this.isAutoFilling) {
          this.switchToManualInput('number_tdc');
        }
      });
    
      this.form.get('name_tdc')?.valueChanges.subscribe(() => {
        if (!this.isAutoFilling) {
          this.switchToManualInput('name_tdc');
        }
      });
    
      this.form.get('month')?.valueChanges.subscribe(() => {
        if (!this.isAutoFilling) {
          this.switchToManualInput('month');
        }
      });
    
      this.form.get('year')?.valueChanges.subscribe(() => {
        if (!this.isAutoFilling) {
          this.switchToManualInput('year');
        }
      });
    
      // Manejador especial para CVV: no activar modo manual
      this.form.get('ccv')?.valueChanges.subscribe(() => {
        if (this.isAutoFilling) {
          //console.log('El usuario ingresó el CVV, pero no se cambia a modo manual.');
        } else {
          //console.log('Cambio detectado en el campo ccv, manteniendo el estado actual.');
        }
      });
    }
    
    
    
    
    
    
    // Función para cambiar a entrada manual si se detecta un cambio
    private switchToManualInput(fieldName: string): void {
      if (!this.isManualInput) {
        //console.log(`Cambio detectado en el campo ${fieldName}, cambiando a entrada manual.`);
        this.isManualInput = true;
        
        // Limpiar los datos específicos de la tarjeta guardada
        this.tdcID = null;
        this.tdcStatus = null;
        this.tdcCorerID = null;
      }
    }
    

    private loadPartnerData(): void {
      this.web.get(`${this.web.HOST}/partner/${localStorage.getItem("partner")}`).subscribe(response => {
        this.provinces = response.result.provinces.sort((a, b) => a.name.localeCompare(b.name));
        this.partnerCorregimientos = response.result.partnerCorregimientos;
        console.log("ESTOS SON LOS CORRGIMIENTOS QUE TIENE EN LA TABLA NUEVA: ", this.partnerCorregimientos);
        this.loadPlanData();
      }, err => {
      //  this.loader.close();
        this.notification.showError(err);
      });
    }

    private loadPlanData(): void {
      this.web.get(`${this.web.HOST}/plan/${this.planService.getPlanSelected()}`).subscribe(response => {
        this.plan = response.result;
      //  this.loader.close();
        this.calculateDates();
      }, err => {
      //  this.loader.close();
        this.notification.showError(err);
      });
    }
  
    private calculateDates(): void {
      const monthFrecuency = parseInt(this.plan.frecuency.month_frecuency);
      const qtyInstallments = this.plan.qty_installments_to_collect_plan;
      const datox = monthFrecuency * qtyInstallments;
  
      const today = new Date();
      this.todayDate = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
  
      this.calculatedDate = new Date(today);
      this.calculatedDate.setMonth(this.calculatedDate.getMonth() + datox);
    }

    

    // TERMINA SETUP

  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }

  togglemisdirecciones() {
    if (!this.isDialogOpen) {
      this.isDialogOpen = true; // Marca que el diálogo está abierto
      const dialogRef = this.dialog.open(MisdireccionesComponent, {
        width: '800px', height: '550px'
      });

      dialogRef.afterClosed().subscribe((res) => {
        // Restablece el indicador cuando se cierra el diálogo
        this.isDialogOpen = false;
        if (!res) {
          // If user press cancel
          return;
        }
        // TOMAMOS LA INFORMACION DE LA DIRECCION SELECCIONADA
        ////console.log(res);
        this.addressID = res.id;
        this.addressIdSelected = this.addressID;
        this.form.controls["name"].setValue(res.name != null ? res.name : '');
        this.form.controls["lastname"].setValue(res.lastname != null ? res.name : '');
        this.form.controls["address"].setValue(res.adress);
        this.form.controls["province"].setValue(res.province.id);
        this.form.controls["corregimiento"].setValue(res.corregimiento.id);

        this.onProvinceChargeChange(res.province.id);
      });
    }
  }
  togglemistarjetas() {
    if (!this.isDialogOpen) {
      this.isDialogOpen = true; // Marca que el diálogo está abierto
      const dialogRef = this.dialog.open(MistarjetasComponent, {
        width: '800px', height: '550px'
      });
  
      dialogRef.afterClosed().subscribe((res) => {
        // Restablece el indicador cuando se cierra el diálogo
        this.isDialogOpen = false;
  
        if (!res) {
          //console.log('No se seleccionó ninguna tarjeta.');
          return;
        }
  
        //console.log('Tarjeta seleccionada:', res); // Mostrar los datos de la tarjeta seleccionada
  
        this.isManualInput = false; // Desactivar validaciones de entrada manual
        this.isAutoFilling = true; // Activar la bandera de auto-llenado
  
        // Rellenar automáticamente los datos de la tarjeta
        this.tdcID = res.token;
        this.tdcStatus = res.estatus;
        this.tdcCorerID = res.id;
  
        this.form.controls["name_tdc"].setValue(res.nameHolderCreditCard);
        this.form.controls["number_tdc"].setValue(res.maskCreditCard);
        this.form.controls["month"].setValue(res.expMonthCreditCard);
        this.form.controls["year"].setValue(res.expYearCreditCard);
        this.form.controls["ccv"].setValue(''); // CVV se deja vacío para seguridad
  
        // Desactivar la bandera después de que los datos hayan sido rellenados
        setTimeout(() => {
          this.isAutoFilling = false;
        }, 0);
      });
    }
  }
  

  onProvinceChargeChange(id: any): void {
    this.corregimientos = [];
    this.addressID = null;
    const uniqueCorregimientos = new Map();
    this.partnerCorregimientos
      .filter(c => c.province.id === Number(id))
      .forEach(c => {
        if (!uniqueCorregimientos.has(c.corregimiento.id)) {
          uniqueCorregimientos.set(c.corregimiento.id, c.corregimiento);
        }
      });
    this.corregimientos = Array.from(uniqueCorregimientos.values())
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  onProvinceChange(): void {
    this.onProvinceChargeChange(this.form.value.province);
  }

  validateCreditCardExpiration(): boolean {
    const expMonth = parseInt(this.form.controls['month'].value);
    const expYear = parseInt(this.form.controls['year'].value) + 2000; // Assuming the year is in YY format


    if (expYear > this.calculatedDate.getFullYear()) {
      return true;
    } else if (expYear === this.calculatedDate.getFullYear() && expMonth > (this.calculatedDate.getMonth() + 1)) {
      return true;
    }
    return false;
  }

  checkout(): void {
    //console.log('Iniciando proceso de checkout...');
    this.openLoader(); // Abrir el loader al inicio del proceso
    
    if (this.planService.getIsActive().toLowerCase() !== 'true') {
      //console.log('Modo Preview activo, no se permiten suscripciones.');
      this.closeLoader();
      return this.notification.showInfo("Estas en modo Preview, este modo NO permite suscripciones");
    }
  
    if (!this.isPayedPortal || localStorage.getItem("step") === "false") {
      //console.log('Pago del portal incompleto o paso no finalizado.');
      this.closeLoader();
      return this.notification.showError("Por favor, culmina el pago de tu portal para que se pueda desplegar correctamente...");
    }
  
    if (!this.validateCreditCardExpiration()) {
      //console.log('Validación de expiración de tarjeta fallida.');
      this.closeLoader();
      return this.notification.showError("La tarjeta no cumple con las cuotas a cobrar.");
    }
  
    if (!this.auth.isLoggedIn()) {
      if (!this.form.value.email) {
        //console.log('Email no proporcionado, usuario no autenticado.');
        this.closeLoader();
        return this.notification.showError("Por favor ingrese su email...");
      }
  
      //console.log('Usuario no autenticado, verificando existencia de cuenta...');
      this.web.get(`${this.web.HOST}/auth/${this.form.value.email}/${this.plan.service.partner.id}`).subscribe(
        response => {
          //console.log('Usuario existente, mostrando popup de login.');
          this.closeLoader(); // Cerrar el loader aquí después de recibir la respuesta
          this.notification.showInfo("Por favor inicia sesion para continuar con la compra...");
          this.showLoginPopup(this.form.value.email);
        },
        err => {
          //console.log('Error al verificar la existencia de la cuenta:', err);
          this.closeLoader(); // Cerrar el loader en caso de error
          this.sendSignUp();
        }
      );
      return;
    }
  
    if (this.form.value.email == null || this.form.value.email === '') {
      //console.log('Email no proporcionado, asignando email por defecto.');
      this.form.controls["email"].setValue("example@gmail.com");
    }
  
    if (!this.form.valid) {
      //console.log('Formulario no válido. Revisa los campos.');
      this.closeLoader();
      return this.notification.showError("Por favor completa el formulario...");
    }
  
    const data = this.form.value;
    //console.log('Formulario válido, datos de envío:', data);
  
    let tdc: any;
    if (this.isManualInput) {
      tdc = {
        numberCreditCard: data.number_tdc.replace(/-/g, ''),
        nameHolderCreditCard: data.name_tdc,
        expMonthCreditCard: data.month,
        expYearCreditCard: data.year,
        ccv: data.ccv
      };
    } else {
      tdc = {
        id: this.tdcID,
        status: this.tdcStatus,
        idcorer: this.tdcCorerID,
        ccv: data.ccv
      };
    }
  
    //console.log('Datos de tarjeta de crédito listos para envío:', tdc);
  
    this.tdc = 'XXXXXXXXXXX' + (this.isManualInput ? data.number_tdc.replace(/-/g, '').slice(-4) : '');
    console.log(this.addressID);
    const address = this.addressIdSelected ? { id: this.addressIdSelected } : {
      name: data.name,
      lastname: data.lastname,
      address: data.address,
      province: data.province,
      corregimiento: data.corregimiento
    };
  
    //console.log('Datos de dirección listos para envío:', address);

    const send = {
      param: 8,
      creditcard: tdc,
      address: address,
      customer: this.auth.getCustomer(),
      plan: this.plan.id,
      extraData: { // INICIALIZAMOS EL OBJETO CON LA EXTRA DATA QUE NECESITAMOS
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        street: this.form.value.street,
        city: this.form.value.city,
        postalCode: this.form.value.postalCode,
        phone : this.form.value.phone,
        httpAcceptBrowserValue : this.httpAcceptBrowserValue,
        httpAcceptContent : this.httpAcceptContent,
        httpBrowserColorDepth : this.httpBrowserColorDepth,
        httpBrowserJavaEnabled : this.httpBrowserJavaEnabled,
        httpBrowserJavaScriptEnabled : this.httpBrowserJavaScriptEnabled,
        httpBrowserLanguage : this.httpBrowserLanguage,
        httpBrowserTimeDifference : this.httpBrowserTimeDifference
      }
    };
  
    const url = `${this.web.HOST}/suscription`;
  
    // console.log('Datos finales para envío:', JSON.stringify(send, null, 2));
    // console.log('URL a la que se enviarán los datos:', url);

    // Realizar la solicitud
    this.web.suscribe(send, url).subscribe(
      response => {
        //console.log('Respuesta del servidor:', response);
        this.closeLoader(); // Cierra el loader después de recibir una respuesta
        this.handleResponse(response);
      },
      err => {
        //console.log('Error durante el envío:', err);
        this.closeLoader(); // Cierra el loader en caso de error
        this.notification.showError(err.message ?? err);
      }
    );
  }
  
  
  
  
  
  

  sendSignUp() {
    if (!this.form.valid) {
      return this.notification.showError("Por favor completa el formulario para crear tu cuenta y continuar con la compra...");
    }
  
    // this.loader.open();
  
    const signinData = {
      name_customer: this.form.value.name,
      lastName_customer: this.form.value.lastname,
      identifier_customer: this.form.value.email,
      adress_customer: this.form.value.address,
      email_customer: this.form.value.email,
      province: this.form.value.province,
      corregimiento: this.form.value.corregimiento,
      config: this.onboardingCongigID,
      status_customer: 1, // COLOCAMOS ESTATUS E ACTIVO AL CLIENTE
      code_customer: this.form.value.email, // COMO ES CLIENTE DE LANDING, SU EMAIL SERA EL CODIGO DEL CLIENTE
      partner: { id: +this.plan.service.partner.id }, // ESTE ES EL PARTNER QUE ES DUEÑO DEL PLAN Y SERVICIO
      service: +this.plan.service.id
    };
  
    this.web.post(signinData, `${this.web.HOST}/onboarding/user`).subscribe({
      next: (result) => {
     //   this.loader.close();
        this.loginService.removeFromCheckout();
        this.loginService.setPasswordFromCheckout(result.accessToken);
        this.showLoginPopup(result.customer.email_customer);
      },
      error: (err) => {
    //    this.loader.close();
        this.notification.showError(err.message || err);
      }
    });
  }

  showLoginPopup(email: string) {
    this.loginService.isFromCheckout(email);
    let dialogRef: MatDialogRef<any> = this.dialog.open(LoginPopupComponent, {
      width: '100%',
      disableClose: true,
      data: {}
    })
    dialogRef.afterClosed().subscribe(res => {
      this.showMenu = true;

      if (res == 0) {
        // If user press cancel
        return;
      }
      this.checkout();
    });
  }

  myLoadEvent() {
    // this.loader.open();
    this.web.get(this.web.HOST + "/hasplan/" + this.hasPlanID).subscribe(chp => {
      this.closeLoader();
      if (chp.result.status_customerPlan != 4) {
        if (chp.result.status_customerPlan == 1) { // ESTO ES QUE SE COBRO Y TODO BIEN CON LA SUSCRIPCION ESTA ACTIVA
          this.emailCustomer = chp.result.customer.email_customer;
          this.router.navigateByUrl("/congratulation");
        } else {
          this.notification.showError("Su pago fue rechazado por el banco");
        }
      }
    }, err => {
   //   this.loader.close();
      this.notification.showError(err);
    });
  }

  onlyNumberKey(event: any) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  toggleupdatesub() {
    if (!this.isDialogOpen) {
      this.isDialogOpen = true; // Marca que el diálogo está abierto
      const dialogRef = this.dialog.open(MisdireccionesComponent, {
        width: '800px', height: '650px'
      });
      dialogRef.afterClosed().subscribe(() => {
        // Restablece el indicador cuando se cierra el diálogo
        this.isDialogOpen = false;
        window.location.reload();
      });
    }
  }

  // 3DS DINAMICO
  private handleResponse(response: any): void {
    // console.log("RESPONSE DEL PROCESO 3DS: ", response)
    //this.closeLoader();
    this.hasPlanID = response.id;
    


    if (response.bank === 'powerTranz') {
      //console.log("POWERTRANZ")
      this.hasPlanID = response.id;
      this.handlePowerTranzResponse(response);
    } else if (response.bank === 'cyberSource') {
       console.log("CYBERSOURCE")
      this.handleCyberSourceResponse(response);
    } else if (response.bank === 'emetec') {
      //console.log("EMETEC")
      this.handleEmetecResponse(response);
    } else {
      if(response.message == "Successfull created"){ // LA TARJETA PUEDE ESTAR VALIDA PARA 3DS
        this.hasPlanID = response.result;
        this.myLoadEvent();
      }else{
        this.notification.showError("ESTE BANCO NO ESTA REGISTRADO");
      }
    }
  }
  // TERMINA PROCESO DE 3DS DINAMICO

  // PROCESO PARA PROWERTRANZ
  private handlePowerTranzResponse(response: any): void {
    if (response.htmlFormData) {
    //this.loader.close();
    this.openLoader();
      //console.log('hola')
      this.openFacPopup(response.htmlFormData);
      
    } else {
      this.hasPlanID = response.result;
      this.emailCustomer = this.form.value.email;
      this.myLoadEvent();
    }
  }

  private openFacPopup(htmlFormData: string): void {
    //console.log('entre');

    // Mostrar spinner por 4 segundos
    this.showLoader(); // Abre el loader por encima de todo
    //console.log('lod',this.showLoader);
    // Añadir el fondo al body
    const backdrop = document.createElement('div');
    backdrop.className = 'custom-backdrop';
    document.body.appendChild(backdrop);

    const dialogRef = this.dialog.open(FacPopupComponent, {
        width: '600px',
        disableClose: true,
        data: { form: this.sanitizer.bypassSecurityTrustHtml(htmlFormData), id: this.hasPlanID },
        panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(() => {
        this.myLoadEvent();
        document.body.removeChild(backdrop); // Remover el fondo después de cerrar el popup
    });
}

// Método para mostrar el loader con z-index alto
private showLoader(): void {
    // Establecer estilos para el loader
    const loaderElement = document.createElement('div');
    loaderElement.className = 'custom-loader'; // Clase CSS personalizada para el loader
    loaderElement.style.position = 'fixed';
    loaderElement.style.top = '50%';
    loaderElement.style.left = '50%';
    loaderElement.style.transform = 'translate(-50%, -50%)';
    loaderElement.style.zIndex = '999999'; // Z-index alto para estar por encima de todo
    loaderElement.style.backgroundColor = 'rgba(255, 255, 255, 0.8)'; // Fondo semi-transparente
    loaderElement.innerHTML = `
        <div class="spinner"> </div>
    `;
    document.body.appendChild(loaderElement);

    // Cerrar el spinner después de 4 segundos
    setTimeout(() => {
        document.body.removeChild(loaderElement);
    }, 8000);
}


  // TERMINA PROCESO PARA POWERTRANZ

   // PROCESO PARA CYBERSOURCE
   private handleCyberSourceResponse(response: any): void {
     this.loader.open();
     console.log("RESPONSE CYBERSOURCE: ", response);
     console.log("Validando si el estado de la transacción es COMPLETED...");
    if (response.sopResponse.responseCodeDescription !== "COMPLETED") {
      // this.loader.close();
      console.log("Estado de la transacción no es COMPLETED, terminando flujo.");
      this.notification.showError("ERROR - PAYER AUTH DISTINTO DE COMPLETE");
      return;
    }

    const transactionIdentifier = response.sopResponse.transactionId;
    const jwtToken = response.sopResponse.auth3DSDetail.token;
    this.uniqueCodeService.setTransactionIdentifier(transactionIdentifier);
    console.log("Transaction Identifier:", transactionIdentifier);
    console.log("JWT Token para 3DS:", jwtToken);
    if (environment.production) {
      this.createAndSubmitIframeForm(
        jwtToken, 
        "https://centinelapi.cardinalcommerce.com/V1/Cruise/Collect", 
        "ddc-iframe", 
        "ddc-form"
      );
    } else {
      console.log("Enviando datos al iframe para recolectar información 3DS...");
      console.log("URL:", "https://centinelapistag.cardinalcommerce.com/V1/Cruise/Collect");
      this.createAndSubmitIframeForm(
        jwtToken, 
        "https://centinelapistag.cardinalcommerce.com/V1/Cruise/Collect", 
        "ddc-iframe", 
        "ddc-form"
      );
    }

    setTimeout(() => {
      const updatedData = this.transactionenrollment();
      const json = { chp: response.id, sop: updatedData, call: "enrollment" };
      console.log("Preparando datos para enrolamiento con CyberSource...");
      console.log("Datos enviados:", JSON.stringify(json, null, 2));
      // json.sop.creditCard.cardholderName =  this.form.value.nameHolderCreditCard;
      // json.sop.creditCard.creditCardDate = this.form.value.expYearCreditCard + this.form.value.expMonthCreditCard;
       console.log("CONE ESTAO HACEMOS EL ENROLLMENT")
       console.log(JSON.stringify(json, null, 2))
      this.web.post(json, `${this.web.HOST}/sop`).subscribe(enrollment => {
        console.log("Respuesta del enrolamiento recibido:", enrollment);
        this.handleEnrollmentResponse(enrollment);
      }, err => {
        this.loader.close()
        console.error("ERROR LLAMANDO A ENROLAMIENTO: ", err);
      });
    }, 10000);
  }

  private handleEnrollmentResponse(enrollment: any): void {
    console.log("RESPUESTA DEL ENROLLMENT: ", enrollment);
  
    switch (enrollment.responseCodeDescription) {
      case "AUTHENTICATION_SUCCESSFUL":
        console.log("Successful Frictionless Authentication");
        this.handleEnrollmentSuccess(enrollment);
        break;
  
      case "AUTHENTICATION_FAILED":
        console.log("Unsuccessful Frictionless Authentication");
        this.loader.close();
        this.notification.showError("AUTHENTICATION_FAILED");
        break;
  
      case "PENDING_AUTHENTICATION":
        console.log("Pending Authentication - Step-Up Required");
        this.handlePendingAuthentication(enrollment);
        break;
  
      default:
        console.log("Unexpected response code from enrollment");
       this.loader.close();
        this.notification.showError("Unexpected response code from enrollment : " + enrollment.responseCodeDescription );
        break;
    }
  }

  private handlePendingAuthentication(secondResponse: any): void {
    // this.loader.open()
    console.log('SE REALIZA PROCESO DE 3DS YA QUE ENROLLMENT RETORNO PENDING_AUTHENTICATION');
    this.startPolling();
  
    this.jwtTokenForThird = secondResponse.auth3DSDetail.htmlCode;
    this.transactionIdForThird = secondResponse.transactionId;
    console.log('htmlCode para el 3DS:', this.jwtTokenForThird);
  
    if (secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "C") {
      console.log("Successful Step-Up Authentication");
    }
  
    this.createAndSubmitStepUpForm(this.jwtTokenForThird);
  }
  
  //YOSOYNICO
  createAndSubmitStepUpForm(jwtToken: string) {
    //this.spinner.close();
    console.log('Iniciando StepUp para 3DS');
    
    // Crear el iframe y aplicarle los estilos de centrado
    const iframe = document.createElement('iframe');
    iframe.name = 'step-up-iframe';
    iframe.height = '900';
    iframe.width = '900';
    iframe.style.display = 'block';
    iframe.style.position = 'fixed';
    iframe.style.top = '50%';
    iframe.style.left = '50%';
    iframe.style.transform = 'translate(-50%, -50%)'; // Centrar en la pantalla
    iframe.style.zIndex = '99999';
    iframe.style.background = 'white';
  
    // Añadir el iframe directamente al body
    document.body.appendChild(iframe);
    
    this.iframeElement = iframe; // Guardar referencia al iframe
  
    console.log('Iframe creado con las siguientes propiedades:');
    console.log('Name:', iframe.name);
    console.log('Height:', iframe.height);
    console.log('Width:', iframe.width);

  
    // Crear el formulario
    const form = document.createElement('form');
    form.id = 'step-up-form';
    form.target = 'step-up-iframe';
    form.method = 'post';
    if (environment.production) {
      // Producción
      form.action = 'https://centinelapi.cardinalcommerce.com/V2/Cruise/StepUp';
    } else {
      // Desarrollo
      form.action = 'https://centinelapistag.cardinalcommerce.com/V2/Cruise/StepUp';
    }
    
    const inputJWT = document.createElement('input');
    inputJWT.type = 'hidden';
    inputJWT.name = 'JWT';
    inputJWT.value = jwtToken;
    form.appendChild(inputJWT);
    
    const inputMD = document.createElement('input');
    inputMD.type = 'hidden';
    inputMD.name = 'MD';
    inputMD.value = 'optionally_include_custom_data_that_will_be_returned_as_is';
    form.appendChild(inputMD);

    const inputFingerprint = document.createElement('input');
    inputFingerprint.type = 'hidden';
    inputFingerprint.name = 'fingerprint';
    inputFingerprint.value = this.uniqueCode; // Enviar el fingerprint
    form.appendChild(inputFingerprint);

    console.log('Formulario creado para StepUp con los siguientes datos:');
    console.log('Action URL:', form.action);
    console.log('JWT:', jwtToken);
    console.log('MD:', inputMD.value);
    console.log('Fingerprint:', inputFingerprint.value);
    
    document.body.appendChild(form);
    console.log('Formulario de StepUp creado y agregado al DOM:', form);
  
    form.submit();
    console.log('Formulario de StepUp enviado');
  
    iframe.onload = () => {
      console.log('Iframe StepUp loaded and displayed.');
    };
    
    const expectedOrigin = environment.production
  ? "https://centinelapi.cardinalcommerce.com"  // Producción
  : "https://centinelapistag.cardinalcommerce.com";  // Desarrollo

window.addEventListener(
  "message",
  (event) => {
    if (event.origin === expectedOrigin) {
      console.log('Mensaje StepUp recibido desde:', event.origin);
      let data = JSON.parse(event.data);
      console.log('Mensaje StepUp recibido:', data);

      if (data.success) {
        // this.spinner.open();
        if (this.iframeElement) {
          if (this.iframeElement.parentNode) {
            this.iframeElement.parentNode.removeChild(this.iframeElement); // Eliminar el iframe del DOM
          }
        }
      }
    }
  },
  false
);
  }
  
  //fin

  private transactionenrollment(additionalData: any = {}): any {
    return {
      creditCard: {
        cardholderName: this.form.value.nameHolderCreditCard,
        creditCardDate: this.form.value.expYearCreditCard + this.form.value.expMonthCreditCard,
        cvv: this.form.value.ccv
      },
      transactionIdentifier: this.uniqueCodeService.getTransactionIdentifier(),
      currency: "USD",
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email: localStorage.getItem('username'),
        street: this.form.value.street,
        city: this.form.value.city,
        postalCode: this.form.value.postalCode,
        phone : this.form.value.phone,
        httpAcceptBrowserValue : this.httpAcceptBrowserValue,
        httpAcceptContent : this.httpAcceptContent,
        httpBrowserColorDepth : this.httpBrowserColorDepth,
        httpBrowserJavaEnabled : this.httpBrowserJavaEnabled,
        httpBrowserJavaScriptEnabled : this.httpBrowserJavaScriptEnabled,
        httpBrowserLanguage : this.httpBrowserLanguage,
        httpBrowserTimeDifference : this.httpBrowserTimeDifference,
        ...additionalData
      },
      auth: additionalData.auth
    };
  }

  startPolling(): void {
    // this.loader.open();
    const url = `${this.web.HOST}/hasplan/${this.hasPlanID}`;
    let polling = true;
  
    interval(3000).pipe(
      takeWhile(() => polling),
      switchMap(() => this.web.get(url).pipe(
        catchError(error => {
          if (error.status === 404) {
         //   this.loader.close();
            //console.log('Respuesta 404: No encontrado');
            return of(null);
          } else {
          //  this.loader.close();
            console.error('Error en la solicitud:', error);
            return of(null);
          }
        })
      ))
    ).subscribe(response => {
      if (response && response?.result) {
        //console.log("RESPUESTA", response);
  
        if (response.result.status_customerPlan == 100) {
        //  this.loader.close();
          polling = false;
          this.pollingSuccess.next();
          this.removeIframe();
        }
      }
    });
  }

  private handleEnrollmentSuccess(secondResponse: any): void {
    // this.loader.open();
    const fourthData = this.createTransactionData(secondResponse.transactionId, {
      auth: {
        authenticationTransactionId: secondResponse.transactionId
      },
      eci: secondResponse.extraData.eci,
      eciRaw: secondResponse.extraData.eciRaw,
      cavv: secondResponse.extraData.cavv,
      paresStatus: secondResponse.extraData.paresStatus,
      veresEnrolled: secondResponse.extraData.veresEnrolled,
      xid: secondResponse.extraData.xid,
      authenticationTransactionId: secondResponse.extraData.authenticationTransactionId,
      acsTransactionId: secondResponse.extraData.acsTransactionId,
      ecommerceIndicator: secondResponse.extraData.ecommerceIndicator,
      specificationVersion: secondResponse.extraData.specificationVersion,
      directoryServerTransactionId: secondResponse.extraData.directoryServerTransactionId,
      ucafAuthenticationData: secondResponse.extraData.ucafAuthenticationData,
      ucafCollectionIndicator: secondResponse.extraData.ucafCollectionIndicator
    });

    const json = { chp: this.hasPlanID, sop: fourthData, call: "sale" };
    //console.log("CON ESTO HACEMOS EL SALE : ", json)
    this.web.post(json, `${this.web.HOST}/sop`).subscribe(fourthResponse => {
      this.closeLoader();
      //console.log("RESPUESTA DEL SALE: ", fourthResponse)
      this.myLoadEvent();
      
    }, errr =>{
      console.error(errr);
      this.notification.showError(errr)
    });
  }


  private createAndSubmitIframeForm(jwtToken: string, actionUrl: string, iframeName: string, formId: string): void {
    const iframe = document.createElement('iframe');
    iframe.name = iframeName;
    iframe.height = '900';
    iframe.width = '900';
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    this.iframeElement = iframe;

    const form = document.createElement('form');
    form.id = formId;
    form.target = iframeName;
    form.method = 'POST';
    form.action = actionUrl;

    const inputJWT = document.createElement('input');
    inputJWT.type = 'hidden';
    inputJWT.name = 'JWT';
    inputJWT.value = jwtToken;
    form.appendChild(inputJWT);

    document.body.appendChild(form);
    form.submit();

    iframe.onload = () => {
      iframe.style.display = 'block';
    };
    console.log("Enviando JWT Token al iframe:", jwtToken);
console.log("Formulario enviado a URL:", actionUrl);
console.log("Nombre del iframe:", iframeName);
    const expectedOrigin = environment.production
    ? "https://centinelapi.cardinalcommerce.com"  // Producción
    : "https://centinelapistag.cardinalcommerce.com";  // Desarrollo
    window.addEventListener("message", (event) => {
      if (event.origin === expectedOrigin) {
        const data = JSON.parse(event.data);
        console.log('Mensaje recibido:', data);
      }
    }, false);
  }

  private removeIframe(): void {
    if (this.iframeElement) {
     // this.loader.close();
      document.body.removeChild(this.iframeElement);
    }
  }

  continueToNextStep() {
    // this.loader.open();
  
    const transactionIdentifier = this.uniqueCodeService.getTransactionIdentifier();
  
    if (!this.jwtTokenForThird || !this.transactionIdForThird) {
      console.error('No se encontró jwtTokenForThird o transactionIdForThird para la tercera petición.');
    //  this.loader.close();
      return;
    }
  
    const thirdData = this.createTransactionData(transactionIdentifier, {
      auth: {
        authenticationTransactionId: this.transactionIdForThird,
        signedPares: this.jwtTokenForThird
      }
    });
  
    const validateJson = { chp: this.hasPlanID, sop: thirdData, call: "validate" };
    //console.log('REQUEST PARA VALIDATE AUTH', validateJson);
  
    this.web.post(validateJson, this.web.HOST + "/sop")
      .pipe(
        switchMap(thirdResponse => this.handleValidateAuthResponse(thirdResponse, transactionIdentifier)),
        catchError(error => this.handleErrorDuringChain(error))
      )
      .subscribe(fourthResponse => this.handleSaleResponse(fourthResponse));
  }
  
  private handleValidateAuthResponse(thirdResponse: any, transactionIdentifier: string): Observable<any> {
    // this.loader.open();
    //console.log('Respuesta para VALIDATE AUTH:', thirdResponse);
  
    if (thirdResponse?.responseCodeDescription === "AUTHENTICATION_SUCCESSFUL") {
      //console.log("Successful Step-Up Authentication ON VALIDATE AUTH");
  
      const extraData = thirdResponse.extraData;
      const fourthData = this.createTransactionData(transactionIdentifier, {
        auth: {
          authenticationTransactionId: this.transactionIdForThird
        },
        eci: extraData.eci,
        eciRaw: extraData.eciRaw,
        cavv: extraData.cavv,
        authenticationTransactionId: extraData.authenticationTransactionId,
        acsTransactionId: extraData.acsTransactionId,
        paresStatus: extraData.paresStatus,
        veresEnrolled: extraData.veresEnrolled,
        xid: extraData.xid,
        ecommerceIndicator: extraData.ecommerceIndicator,
        specificationVersion: extraData.specificationVersion,
        directoryServerTransactionId: extraData.directoryServerTransactionId,
        ucafAuthenticationData: extraData.ucafAuthenticationData,
        ucafCollectionIndicator: extraData.ucafCollectionIndicator
      });
  
      const saleJson = { chp: this.hasPlanID, sop: fourthData, call: "sale" };
      //console.log('REQUEST PARA SALE', saleJson);
  
      return this.web.post(saleJson, this.web.HOST + "/sop");
    } else {
      //console.log("VALIDATE AUTH RETORNO AUTHENTICATION_FAILED");
      this.notification.showError("AUTHENTICATION_FAILED");
     // this.loader.close();
      return of(null);
    }
  }
  
  private handleErrorDuringChain(error: any): Observable<null> {
    console.error('Error en la cadena de peticiones:', error);
    this.notification.showError(`Error en VALIDATE AUTH: ${error.message}`);
   // this.loader.close();
    return of(null);
  }
  
  private handleSaleResponse(fourthResponse: any): void {
    // this.loader.open();
  
    if (fourthResponse?.responseCodeDescription) {
      //console.log('Respuesta del SALE:', fourthResponse);
  
      if (fourthResponse.responseCodeDescription === 'AUTHORIZED') {
        // this.dialogRef.close(1);
        this.myLoadEvent();
      } else {
       // this.loader.close();
        this.notification.showError("El pago no se procesó correctamente. Por favor, intente nuevamente.");
      }
    } else {
    //  this.loader.close();
      //console.log('SALE no fue exitoso o no se ejecutó debido a un error previo.');
      this.notification.showError("No se obtuvo una respuesta del proceso de pago. Verifique la transacción o intente nuevamente. " + fourthResponse.responseCodeDescription);
    }
  
  //  this.loader.close();
  }

  createTransactionData(transactionIdentifier: string = '', additionalData: any = {}): any {
    return {
      creditCard: {
        cardholderName: this.form.value.nameHolderCreditCard,
        creditCardDate: `${this.form.value.expYearCreditCard}${this.form.value.expMonthCreditCard}`,
        cvv: this.form.value.ccv
      },
      transactionIdentifier: transactionIdentifier,
      currency: "USD",
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email: localStorage.getItem('username'),
        street: this.form.value.street,
        city: this.form.value.city,
        postalCode: this.form.value.postalCode,
        phone: this.form.value.phone,
        httpAcceptBrowserValue : this.httpAcceptBrowserValue,
        httpAcceptContent : this.httpAcceptContent,
        httpBrowserColorDepth : this.httpBrowserColorDepth,
        httpBrowserJavaEnabled : this.httpBrowserJavaEnabled,
        httpBrowserJavaScriptEnabled : this.httpBrowserJavaScriptEnabled,
        httpBrowserLanguage : this.httpBrowserLanguage,
        httpBrowserTimeDifference : this.httpBrowserTimeDifference,
        ...additionalData
      },
      auth: additionalData.auth
    };
  }

  // TERMINA PROCESOS CYBERSOURCE

  // EMETEC PROCESOS

  private handleEmetecResponse(response: any): void {
    this.dataObject = this.buildRequestData();
    this.hasPlanID = response.id;
    this.chp = response.id;

    if (response.sopResponse.responseCodeDescription === "transaction pending") {
      this.handleEmetecPendingTransaction(response);
      //console.log("gueto2")
      //console.log(response)
    } else {//console.log("gueto")
      const updatedData = this.transactionEmetec();
      updatedData.transactionIdentifier = response.sopResponse.transactionId;

      const json = { chp: response.id, sop: updatedData, call: "payment" };
      const voidJson = { chp : response.id, sop: updatedData, call: "void"};
      this.refundJson = { chp : response.id, sop: updatedData, call: "refund" };
      this.getEmetecPayment(json, voidJson);
    }
    
  }

  getEmetecPayment(json: any, voidJson: any): void {
    this.loader.open();
    console.log("USANDO ESTA DATA HACEMOS EL PAYMENT: ", json);
  
    if (this.retryCount < this.maxRetries) {
      this.retryCount++;
      timer(3000).pipe(
        switchMap(() => this.web.post(json, `${this.web.HOST}/sop`)),
        takeWhile(() => this.retryCount <= this.maxRetries)
      ).subscribe(response => {
        //console.log("RETRY RESPONSE PAYMENT: ", response);
  
        if (response.responseCodeDescription === "Transaction succeeded") {
          // this.loader.open();
          this.myLoadEvent();
          setTimeout(() => {
            this.loader.close();
          }, 5000); 
         
        } else if (response.responseCodeDescription === "transaction pending") {
          // Continue retrying
          this.getEmetecPayment(json, voidJson);

        } else {
          setTimeout(() => {
            this.loader.close();
          }, 3000); 
          this.notification.showError("Hubo un error en el proceso: " + response.responseCodeDescription);
          this.emetecVoid(voidJson); // HACEMOS EL VOID DE LA TRANSACCION PORQUE DIO ERROR
        }
      }, err => {
        console.error("ERROR: ", err);
      this.loader.close();
        this.notification.showError("Error en el proceso de pago. Intente nuevamente.");
        this.emetecVoid(voidJson); // HACEMOS EL VOID DE LA TRANSACCION PORQUE DIO ERROR

      });
    } else {
    this.loader.close();
      this.notification.showError("Se alcanzó el número máximo de intentos. Transacción sigue en estatus pending. Contacte con el administrador.");
      this.emetecVoid(voidJson); // HACEMOS EL VOID DE LA TRANSACCION PORQUE DIO ERROR

    }
  }


  emetecVoid(json: any): void {


    console.log("CON ESTO BUSCAMOS HACER EL VOID: ", JSON.stringify(json, null, 2));
    this.web.post(json, `${this.web.HOST}/sop`).subscribe(
        (response) => {
          console.log("VOID EXITOSO");
          this.loader.close();
          // this.myLoadEvent(); // Confirmación del evento final después de void exitoso
        },
        (err) => {
          console.error("Error realizando VOID:", err);
          this.loader.close();
          // this.notification.showError(
          //   "Error realizando VOID de la transaccion, por favor contacte con el administrador"
          // );
          this.emetecRefund(); // COMO EL VOID DIO ERROR INTENTAMOS CON EL REFUND
        }
    );
  }

  emetecRefund() : void {
    this.web.post(this.refundJson, `${this.web.HOST}/sop`).subscribe(
        (response) => {
          console.log("REFUND EXITOSO");
          this.loader.close();
          // this.myLoadEvent(); // Confirmación del evento final después de void exitoso
        },
        (err) => {
          console.error("Error realizando VOID:", err);
          this.loader.close();
          // this.notification.showError(
          //     "Error realizando REFUND de la transaccion, por favor contacte con el administrador"
          // );
        }
    );
  }

  private handleEmetecPendingTransaction(response: any): void {
    // this.loader.open();
    // Store transaction details from the response
    this.transactionId = response.sopResponse.transactionId;
    this.iframeValue = response.sopResponse.auth3DSDetail.iframeValue;
    this.iframeName = response.sopResponse.auth3DSDetail.iframeName;
    this.iframeUrl = response.sopResponse.auth3DSDetail.iframeUrl;
    this.redirectUrl = response.sopResponse.auth3DSDetail.redirectUrl;
  
    //console.log('Datos almacenados:');
    //console.log('transactionId:', this.transactionId);
    //console.log('iframeValue:', this.iframeValue);
    //console.log('iframeName:', this.iframeName);
    //console.log('iframeUrl:', this.iframeUrl);
    //console.log('redirectUrl:', this.redirectUrl);
  
    // Send POST request using fetch with no-cors for the first iframe
    this.sendIframeRequest(this.iframeUrl, this.iframeValue)
      .then(() => {
        //console.log('Solicitud del primer iframe enviada con éxito');
        // this.loader.open(); // Stop the loader before opening the popup
        
        // Open the second iframe in a popup
        this.openInteractiveIframePopup(this.redirectUrl, this.iframeValue, response.sopResponse.transactionId);
      })
      .catch(err => {
        console.error('Error al enviar la solicitud del primer iframe:', err);
        this.notification.showError('Error al enviar la solicitud del primer iframe:' + err);
      //  this.loader.close();
      });
  }
// aqui cosas nuevas
  openInteractiveIframePopup(url: string, iframeValue: string, transactionID: string): void {
    // Abre el diálogo utilizando AppDialogContainerComponent como contenedor
    const dialogRef = this.dialog.open(AppDialogContainerComponent, {
      width: '600px',
      panelClass: 'custom-dialog-zindex' // Aplica la clase CSS personalizada para el z-index si es necesario
    });
  
    // Crear el contenido del diálogo dinámicamente
    const iframeContainer = this.renderer.createElement('div');
    const iframe = this.renderer.createElement('iframe');
    const spinner = this.renderer.createElement('div'); // Spinner del loader
  
    // Configurar elementos del iframe
    this.renderer.setAttribute(iframe, 'src', url);
    this.renderer.setAttribute(iframe, 'width', '100%');
    this.renderer.setAttribute(iframe, 'height', '400px');
    this.renderer.setAttribute(iframe, 'sandbox', 'allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts');
   //spiner connf
    this.renderer.addClass(spinner, 'spinner');
    this.renderer.setStyle(spinner, 'position', 'absolute');
    this.renderer.setStyle(spinner, 'top', '50%');
    this.renderer.setStyle(spinner, 'left', '45%');
    this.renderer.setStyle(spinner, 'transform', 'translate(-50%, -50%)');
    this.renderer.setStyle(spinner, 'z-index', '100000'); // Asegura que el spinner esté por encima del iframe
  
    // Añadir elementos al contenedor del diálogo
    this.renderer.appendChild(iframeContainer, spinner); // Añadir el spinner al contenedor
    this.renderer.appendChild(iframeContainer, iframe); // Añadir el iframe después del spinner
  
  
    dialogRef.afterOpened().subscribe(() => {
      const dialogComponentInstance = dialogRef.componentInstance;
      if (dialogComponentInstance.dialogContent) {
        this.renderer.appendChild(dialogComponentInstance.dialogContent.nativeElement, iframeContainer);
      }
    });
  
    // Ocultar el spinner cuando el iframe haya terminado de cargar
    this.renderer.listen(iframe, 'load', () => {
      this.renderer.setStyle(spinner, 'display', 'none');
    });
  
    // Configurar los datos relevantes
    this.dataObject.transactionIdentifier = transactionID;
  
    // Añadir listener para recibir mensajes del iframe
    window.addEventListener('message', (event) => this.handleIframeMessage(event, dialogRef), false);
  }
  
  /**
   * Maneja los mensajes postMessage recibidos desde el iframe de cloud.billcentrix.com.
   * @param event 
   * @param dialogRef 
   */
  private handleIframeMessage(event: MessageEvent, dialogRef: MatDialogRef<any>): void {

    
    let trustedOrigin = '';

  // Configura la URL confiable según el environment
  if (environment.production) {
    trustedOrigin = "https://cloud.billcentrix.com"; // URL de producción
  } else {
    trustedOrigin = "https://dev-onboarding-new.billcentric.com"; // URL de desarrollo
  }
    // Validar el origen del mensaje
    if (event.origin !== trustedOrigin) {
      console.warn('Mensaje recibido de un origen no confiable:', event.origin);
      return;
    }
  
    // Procesar el mensaje
    const { status, url } = event.data;
    if (status === 'loaded') {
      //console.log(`El iframe ha cargado con la URL: ${url}`);
      
      // Cerrar el loader si estaba abierto
      this.loader.close();
    
      // Cerrar el diálogo después de recibir la respuesta de cloud.billcentrix.com
      this.onIframeLoadedSuccessfully(dialogRef);
    }
  }
  
  /**
   * 
   * @param dialogRef Referencia al diálogo que se cerrará.
   */
  private onIframeLoadedSuccessfully(dialogRef: MatDialogRef<any>): void {
    console.log('Iframe cargado exitosamente, realizando acciones adicionales...');
    // Cerrar el diálogo
    dialogRef.close();
    
    
    const json = { chp: this.chp, sop: this.dataObject, call: "payment" };
    const voidJson = { chp: this.chp, sop: this.dataObject, call: "void"  };
    this.refundJson = { chp : this.chp, sop: this.dataObject, call: "refund" };
    this.getEmetecPayment(json, voidJson);
  }
  
  
//viejo todo lo anterio hay que pasarlo
  private buildRequestData(): any {
    const baseData = {
      creditcard: {
        numberCreditCard: this.form.value.number_tdc.trim(),
        nameHolderCreditCard: this.form.value.name_tdc,
        expMonthCreditCard: this.form.value.month,
        expYearCreditCard: this.form.value.year,
        ccv: this.form.value.ccv
      },
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email: localStorage.getItem('username'),
        street: this.form.value.street,
        city: this.form.value.city,
        postalCode: this.form.value.postalCode,
        phone: this.form.value.phone
      }
    };
  
    return baseData;
  }

  async sendIframeRequest(url: string, iframeValue: string): Promise<void> {
    // Create the request body in URL-encoded format
    const body = new URLSearchParams();
    body.append('threeDSMethodData', iframeValue);
  
    //console.log('Datos que se envían al iframe:');
    //console.log('URL:', url);
    //console.log('Datos del formulario:', body.toString());
  
    // Send the POST request to the iframeUrl
    try {
      let response: Response;
      let attempts = 0;
      const maxAttempts = 2;
      const delay = 1000; // 1 second
  
      do {
        response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: body.toString(),
          mode: 'no-cors', // Add no-cors mode
          credentials: 'include'
        });
  
        if (response.status === 200) {
          //console.log('Solicitud iframe enviada con éxito.');
          break;
        } else {
          console.error(`Intento ${attempts + 1}: Error en la solicitud iframe:`, response.status, response.statusText);
        }
  
        attempts++;
        if (attempts < maxAttempts) {
          await new Promise(resolve => setTimeout(resolve, delay)); // Wait 1 second before the next attempt
        }
      } while (attempts < maxAttempts);
  
      if (attempts === maxAttempts && response.status !== 200) {
        console.error('Se alcanzó el número máximo de intentos y la solicitud no fue exitosa.');
      }
    } catch (error) {
      console.error('Error al enviar la solicitud del primer iframe:', error);
    }
  }

  private transactionEmetec(): any {
    return {
      creditCard: {
        cardholderName: this.form.value.nameHolderCreditCard,
        creditCardDate: this.form.value.expYearCreditCard + this.form.value.expMonthCreditCard,
        cvv: this.form.value.ccv
      },
      transactionIdentifier: this.uniqueCodeService.getTransactionIdentifier(),
      currency: "USD",
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email: localStorage.getItem('username'),
        street: this.form.value.street,
        city: this.form.value.city,
        postalCode: this.form.value.postalCode,
        phone: this.form.value.phone
      }
    };
  }

  // TERMINA PROCESOS PARA EMETEC 



}

// @Component({
//   selector: 'interactive-iframe-dialog',
//   template: `
//     <h1 mat-dialog-title>Complete la verificación</h1>
//     <div mat-dialog-content>
//       <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(data.url)" width="100%" height="400px" sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts"></iframe>
//     </div>
//     <div mat-dialog-actions>
//       <button mat-button (click)="onClose()">Cerrar</button>
//     </div>
//   `,
//   styles: []
// })
// export class InteractiveIframeDialog {
//   constructor(
//     public dialogRef: MatDialogRef<InteractiveIframeDialog>,
//     @Inject(MAT_DIALOG_DATA) public data: { url: string },
//     public sanitizer: DomSanitizer
//   ) {}

//   onClose(): void {
//     this.dialogRef.close();
//     //console.log("AQUI DEBERIA IR EL SALE")
//   }
// }
