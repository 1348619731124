<div class="main" [ngClass]="themeService.currentPalette">
    <div class="login-modal-content">
      <div class="modal-login">

        <form  [formGroup]="form" (ngSubmit)="reset()" >

          <div class="background-white">
            <h1>{{ 'LOGIN.RESET_PASSWORD_TITLE' | translate }}</h1>
           
            <input  [formControl]="form.controls['email']" type="text" [placeholder]="'LOGIN.RESET_PASSWORD_EMAIL_PLACEHOLDER' | translate">
            <small 
            *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" 
            class="form-error-msg">  {{ 'LOGIN.RESET_PASSWORD_EMAIL_REQUIRED' | translate }} </small>
            
            <p>{{ 'LOGIN.RESET_PASSWORD_DESCRIPTION' | translate }}</p>
            <div class="options">
              <button>{{ 'LOGIN.RESET_PASSWORD_BUTTON' | translate }}</button>
            </div>
          </div>
          <div class="transparent-background">
            <p>{{ 'LOGIN.HAVE_PASSWORD' | translate }}</p>
            <a (click)="goToLogin()">{{ 'LOGIN.GO_TO_LOGIN' | translate }}</a>
          </div>

        </form>
      </div>
    </div>
    <img src="../../assets/images/grafica2.png" alt="graff">
  </div>
  