import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { SuscriptionsService } from 'src/app/services/suscription/suscriptions.service';
import { UpdateSuscriptionComponent } from '../../update-suscription/update-suscription.component';

@Component({
  selector: 'app-suscription',
  templateUrl: './suscription.component.html',
  styleUrls: ['../mis-suscripciones.component.scss']
})
export class SuscriptionComponent implements OnInit {

  @Input() plan : { name: String; price: Number; description: String; next_bill_date : string; } | any ;

  suscriptions = [];

  constructor(private notification : NotificationService, private dialog: MatDialog, private suscriptionService : SuscriptionsService) { }
  getStatusTranslation(status: string): string {
    const statusMap: { [key: string]: string } = {
      'Activa': 'ACTIVE',
      'Inactiva': 'INACTIVE',
      'Congelada': 'FROZEN',
      'En proceso de cobro': 'BILLING_IN_PROGRESS',
      'No se pudo cobrar': 'BILLING_FAILED'
    };
    return `SUBSCRIPTIONS.STATUS.${statusMap[status] || 'UNKNOWN'}`;
  }
  ngOnInit(): void {
    this.suscriptionService.suscriptions.subscribe(response => {
      
      this.suscriptions = [];
      this.suscriptions.push(...response);
    }, err => {
     
    });
  }

  onChangeStatus() {
  }

  onUpdate(sus: any) {

    let dialogRef: MatDialogRef<any> = this.dialog.open(UpdateSuscriptionComponent, {
      width: '100%',
      disableClose: true,
      data: { suscription: sus }
    });

    dialogRef.afterClosed()
      .subscribe(res => {

        if (res == 1) {
          this.notification.showSuccess("Suscripción pausada correctamente");
        } else if (res == 2) {
          this.notification.showSuccess("Suscripción cancelada correctamente");
        } else if (res == 0) {
          this.notification.showSuccess("Suscripción activada correctamente");
        } else {
          this.suscriptionService.suscriptionUpdated.emit(false);
          return;
        }

        this.suscriptionService.suscriptionUpdated.emit(true);
      });
  }

}
