<div class="content-dash">
    <div class="title-top">
      <h1>{{ 'CARDS.TITLE' | translate }}</h1>
      <button (click)="toggleAddCardForm()">
        <img src="/assets/icons/plus.svg" alt="">{{ 'CARDS.ACTIONS.ADD_CARD' | translate }}
      </button>
    </div>
    
    <!-- Mostrar solo las tarjetas en la página actual -->
    <app-creditcard-info 
        *ngFor="let t of tdc.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)" 
        [tarjeta]="t" 
        (manageStatus)="changeStatus($event)">
    </app-creditcard-info>
    
    <!-- Controles de paginación -->
    <div class="pagination">
      <button (click)="previousPage()" [disabled]="currentPage === 1">{{ 'CARDS.PAGINATION.PREVIOUS' | translate }}</button>
      <span>{{ 'CARDS.PAGINATION.PAGE' | translate }} {{currentPage}} {{ 'CARDS.PAGINATION.OF' | translate }} {{totalPages}}</span>
      <button (click)="nextPage()" [disabled]="currentPage === totalPages">{{ 'CARDS.PAGINATION.NEXT' | translate }}</button>
    </div>
  </div>
  