import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../theme.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PlanService } from '../services/plan/plan.service';
import { ImagesService } from '../services/image/images.service';
import { WebService } from '../services/web.service';
import { NotificationService } from '../services/notification.service';
import { LoaderService } from '../services/loader.service';
import { Plan } from '../components/plan/model/plan.model';
import { TemplateDataService } from '../services/template-data.service';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-practic',
  templateUrl: './practic.component.html',
  styleUrls: ['./practic.component.scss']
})
export class PracticComponent implements OnInit {
  selectedPalette: string = 'Billcentrix';
  plans: Plan[] = [];

  title: any = "";
  description: any = "";
  price_title: any = "";
  banner_title: any = "";
  banner_description: any = "";
  class: any = "pal1";
  imagePrincipal: any;
  imagePromotional: any = null;
  isSuscriptionActive = true;
  aloudNavigation = true;

  constructor(
    public languageService: LanguageService,
    public themeService: ThemeService,
    private activateRoute: ActivatedRoute,
    private planService: PlanService,
    private imageService: ImagesService,
    private web: WebService,
    private notification: NotificationService,
    private loader: LoaderService,
    private templateDataService: TemplateDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loader.open();
  
    this.activateRoute.params.subscribe(params => {
      const param = params["id"] !== undefined ? params["id"] : -1;
      this.aloudNavigation = (param === -1);
      if (!this.aloudNavigation) {
        this.notification.showInfo("Este modo NO permite generar suscripciones");
      }
  
      // Suscribirse al observable para obtener el objeto real
      this.templateDataService.getResponse().subscribe(response => {
        if (response) {
          console.log("Padre - Received response:", response);
          this.processResponse(response);
        } else {
          // Si no hay respuesta, hacemos la llamada directa como fallback
          this.web.get(this.web.HOST + "/onboardingConfig/bydomain/" + window.location.host + "/" + param)
            .subscribe({
              next: (resp) => {
                console.log("Padre - Fallback response:", resp);
                this.templateDataService.setResponse(resp);
                this.processResponse(resp);
              },
              error: (err) => {
                this.notification.showError(err);
              }
            });
        }
        this.loader.close();
      });
    });
  }

  private processResponse(response: any): void {
    console.log("Padre - Processing response:", response);
    this.showContentPage(response);
  }

  showContentPage(response: any): void {
    if (response.result.onboardingImages != null) {
      this.imagePrincipal = response.result.onboardingImages.principal_banner;
      if (response.result.customerHasPlan != null) {
        const nexBillDate = new Date(response.result.customerHasPlan.next_bill_date);
        const today = new Date();
        const isValidDate = nexBillDate < today;
        console.log("Padre - nexBillDate:", nexBillDate, "today:", today, "isValidDate:", isValidDate);
        if (response.result.customerHasPlan != null &&
            (response.result.customerHasPlan.status_customerPlan !== 1 || !isValidDate)) {
          this.isSuscriptionActive = false;
        }
        this.planService.isSuscriptionActive.emit(this.isSuscriptionActive);
        this.show(response);
      } else {
        this.planService.isSuscriptionActive.emit(this.isSuscriptionActive);
        this.show(response);
      }
    } else {
      this.notification.showInfo("Por favor culmina tu portal...");
    }
  }

  show(response: any): void {
    console.log("Padre - Response plans array:", response.result.plans);
    for (let plan of response.result.plans) {
      console.log("Padre - Plan:", plan);
      if (plan.status_plan === 1) { // Sólo mostramos planes activos
        this.plans.push(new Plan(
          plan.name_plan,
          plan.feeCost_plan,
          plan.description,
          plan.url_image,
          plan.id,
          plan.service,
          plan.is_visible_plan
        ));
      }
    }
    console.log("Padre - Filtered plans:", this.plans);
    this.planService.obtainPlans.emit(this.plans);
    this.imagePrincipal = response.result.onboardingImages.principal_banner;
    this.imagePromotional = response.result.onboardingImages.promotional_banner;
    localStorage.setItem("service", response.result.service.id);
    localStorage.setItem("partner", response.result.partner.id);
    localStorage.setItem("step", this.isSuscriptionActive.toString());
    this.imageService.setLogoImage(response.result.onboardingImages.logo);
    this.imageService.logoImage.emit(response.result.onboardingImages.logo);
    this.imageService.setPrincipalBannerImage(response.result.onboardingImages.principal_banner);
    this.class = response.result.color;
    this.themeService.setPalette(response.result.color);
    this.title = response.result.onboardingPortalContent.page_title;
    this.description = response.result.onboardingPortalContent.description_page;
    this.price_title = response.result.onboardingPortalContent.price_title;
    this.banner_title = response.result.onboardingPortalContent.banner_title;
    this.banner_description = response.result.onboardingPortalContent.description_banner;
  }

  changePalette(palette: string): void {
    this.themeService.setPalette(palette);
  }
}
