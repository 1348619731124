import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TemplateDataService {
  // Inicializamos con null; cualquier suscriptor recibirá el último valor emitido
  private _responseSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() { }

  /**
   * Guarda la respuesta (JSON) que obtuviste del backend
   * y la emite a todos los suscriptores.
   */
  public setResponse(data: any): void {
    this._responseSubject.next(data);
  }

  /**
   * Permite suscribirse al stream de datos.
   */
  public getResponse(): Observable<any> {
    return this._responseSubject.asObservable();
  }

  /**
   * Limpia la data (emite null).
   */
  public clearResponse(): void {
    this._responseSubject.next(null);
  }
}
