<button class="close" mat-icon-button (click)="closeDialog()">
  <mat-icon>close</mat-icon>
</button>
<div class="form_dialog">
  <h1>{{ 'SUBSCRIPTIONS.UPDATE_SECTION.TITLE' | translate }}</h1>

  <form [formGroup]="form">
    <div class="two-column">
      <div class="form-group">
        <label for="product">{{ 'SUBSCRIPTIONS.UPDATE_SECTION.PRODUCT' | translate }}</label>
        <input readonly [value]="form.controls['product'].value.name_plan" type="text" id="product" placeholder="" required>
      </div>
      <div class="form-group">
        <label for="price">{{ 'SUBSCRIPTIONS.UPDATE_SECTION.PRICE' | translate }}</label>
        <input readonly [formControl]="form.controls['price']" type="text" id="price" placeholder="" required>
      </div>
      <div class="form-group">
        <label for="frecuency">{{ 'SUBSCRIPTIONS.UPDATE_SECTION.FREQUENCY' | translate }}</label>
        <input readonly [formControl]="form.controls['frecuency']" type="text" id="frecuency" placeholder="" required>
      </div>
      <div class="form-group" *ngIf="data.suscription.status !== 3 && data.suscription.status !== 2">
        <label for="next">{{ 'SUBSCRIPTIONS.UPDATE_SECTION.NEXT_BILLING' | translate }}</label>
        <input readonly [formControl]="form.controls['next']" type="text" id="next" placeholder="" required>
      </div>
    </div>
    <hr>
    <div style="text-align: end;">
      <a class="text-dark" type="button" style="cursor: pointer;" (click)="addressesPopUp({})">
        {{ 'SUBSCRIPTIONS.UPDATE_SECTION.CHANGE_ADDRESS' | translate }}
      </a>
    </div>
    <div class="three-column">
      <div class="form-group">
        <label for="province">{{ 'SUBSCRIPTIONS.UPDATE_SECTION.PROVINCE' | translate }}</label>
        <input readonly [formControl]="form.controls['province']" type="text" id="province" placeholder="" required>
      </div>
      <div class="form-group">
        <label for="corregimiento">{{ 'SUBSCRIPTIONS.UPDATE_SECTION.DISTRICT' | translate }}</label>
        <input readonly [formControl]="form.controls['corregimiento']" type="text" id="corregimiento" placeholder="" required>
      </div>
      <div class="form-group">
        <label for="adress">{{ 'SUBSCRIPTIONS.UPDATE_SECTION.ADDRESS' | translate }}</label>
        <input readonly [formControl]="form.controls['adress']" type="text" id="adress" placeholder="" required>
      </div>
    </div>
    <br>
    <div style="text-align: end;">
      <a class="text-dark" type="button" style="cursor: pointer;" (click)="tdcPopUp({})">
        {{ 'SUBSCRIPTIONS.UPDATE_SECTION.CHANGE_CARD' | translate }}
      </a>
    </div>
    <div class="two-column">
      <div class="form-group">
        <label for="name_tdc">{{ 'SUBSCRIPTIONS.UPDATE_SECTION.CARD_HOLDER' | translate }}</label>
        <input readonly [formControl]="form.controls['name_tdc']" type="text" id="name_tdc" placeholder="" required>
      </div>
      <div class="form-group">
        <label for="number_tdc">{{ 'SUBSCRIPTIONS.UPDATE_SECTION.CARD_NUMBER' | translate }}</label>
        <input readonly [formControl]="form.controls['number_tdc']" type="text" id="number_tdc" placeholder="" required>
      </div>
    </div>
  </form>

  <div *ngIf="data.suscription.status == 3" style="text-align: end;">
    <a class="text-dark" type="button" style="cursor: pointer;" (click)="validateSubscription('Activar', 1)">
      {{ 'SUBSCRIPTIONS.UPDATE_SECTION.ACTIVATE_SUBSCRIPTION' | translate }}
    </a>
  </div>

  <div *ngIf="data.suscription.status == 1 && canPause" style="text-align: end;">
    <a style="display: block;" class="text-dark" type="button" style="cursor: pointer;" (click)="validateSubscription('Pausar', 2)">
      {{ 'SUBSCRIPTIONS.UPDATE_SECTION.PAUSE_SUBSCRIPTION' | translate }}
    </a>
  </div>

  <div class="botones">
    <button (click)="update()">{{ 'SUBSCRIPTIONS.UPDATE_SECTION.UPDATE_BUTTON' | translate }}</button>
    <div *ngIf="data.suscription.status != 2 && canCancel" style="text-align: end;">
      <a class="text-dark" type="button" style="cursor: pointer;" (click)="validateSubscription('Cancelar', 3)">
        {{ 'SUBSCRIPTIONS.UPDATE_SECTION.CANCEL_SUBSCRIPTION' | translate }}
      </a>
    </div>
  </div>
</div>
