<button class="close" mat-icon-button (click)="closeDialog()">
  <mat-icon>close</mat-icon>
</button>

<div class="form_dialog">
  <h1>{{ 'ADDRESS.ADD_TITLE' | translate }}</h1>

  <form [formGroup]="form">
    <div class="form-group">
      <label for="provincia">{{ 'ADDRESS.FORM.PROVINCE' | translate }}</label>
      <select
        (change)="onProvinceChange()"
        id="provincia"
        [formControl]="form.controls['province']"
        required
      >
        <option value="" disabled>{{ 'ADDRESS.FORM.SELECT_PROVINCE' | translate }}</option>
        <option *ngFor="let p of provinces" [value]="p.id">{{ p.name }}</option>
      </select>
      <small
        *ngIf="form.controls['province'].hasError('required') && form.controls['province'].touched"
        class="form-error-msg"
      >
        {{ 'ADDRESS.FORM.PROVINCE_REQUIRED' | translate }}
      </small>
    </div>

    <div class="form-group">
      <label for="provincia">{{ 'ADDRESS.FORM.DISTRICT' | translate }}</label>
      <select
        id="provincia"
        [formControl]="form.controls['corregimiento']"
        required
      >
        <option value="" disabled>{{ 'ADDRESS.FORM.SELECT_DISTRICT' | translate }}</option>
        <option *ngFor="let c of corregimientos" [value]="c.id">{{ c.name }}</option>
      </select>
      <small
        *ngIf="form.controls['corregimiento'].hasError('required') && form.controls['corregimiento'].touched"
        class="form-error-msg"
      >
        {{ 'ADDRESS.FORM.DISTRICT_REQUIRED' | translate }}
      </small>
    </div>

    <div class="fullwidth">
      <input
        type="text"
        id="direcciones"
        [formControl]="form.controls['adress']"
        placeholder="{{ 'ADDRESS.FORM.ADD_ADDRESS_PLACEHOLDER' | translate }}"
        required
      />
      <small
        *ngIf="form.controls['adress'].hasError('required') && form.controls['adress'].touched"
        class="form-error-msg"
      >
        {{ 'ADDRESS.FORM.ADDRESS_REQUIRED' | translate }}
      </small>
    </div>

    <button (click)="create()">{{ 'ADDRESS.FORM.CREATE_BUTTON' | translate }}</button>
  </form>
</div>
