import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WebService } from './web.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private supportedLanguages = ['en', 'es']; // Idiomas soportados
  private defaultLanguage = 'es'; // Idioma predeterminado

  constructor(private translate: TranslateService, private web: WebService) {
    // Inicializamos el idioma automáticamente
    this.initializeLanguage();
  }

  private initializeLanguage(): void {
    const domain = window.location.host; // Cambia esto si es dinámico
    const endpoint = `${this.web.HOST}/onboardingConfig/bydomain/${domain}/-1`;

    console.log('[LanguageService] Realizando consulta al backend para obtener el idioma:', endpoint);

    this.web.get(endpoint).pipe(
      map((response: any) => {
        console.log('[LanguageService] Respuesta del backend:', response);

        // Extraemos el idioma desde el campo `onboarding_language`
        const language = response.result?.onboarding_language || this.defaultLanguage;
        console.log('[LanguageService] Idioma detectado desde el backend:', language);

        this.setLanguage(language);
      }),
      catchError((error) => {
        console.error('[LanguageService] Error al obtener el idioma del backend:', error);
        this.setLanguage(this.defaultLanguage); // Fallback al idioma predeterminado
        return [];
      })
    ).subscribe();
  }

  private setLanguage(language: string): void {
    const selectedLanguage = this.supportedLanguages.includes(language)
      ? language
      : this.defaultLanguage;

    console.log('[LanguageService] Configurando idioma:', selectedLanguage);
    this.translate.setDefaultLang(selectedLanguage);
    this.translate.use(selectedLanguage);
  }
}
