<div class="main" [ngClass]="themeService.currentPalette">
    <div class="login-modal-content">
      <div class="modal-login">
        <div class="background-white">

          <form [formGroup]="form" (ngSubmit)="signup()" >
            <h1>{{ 'LOGIN.REGISTRATION_TITLE' | translate }}</h1>
            <input [formControl]="form.controls['name_customer']"  type="text" [placeholder]="'LOGIN.NAME_PLACEHOLDER' | translate">
            <small
                                            *ngIf="form.controls['name_customer'].hasError('required') && form.controls['name_customer'].touched"
                                            class="form-error-msg">  {{ 'LOGIN.NAME_REQUIRED' | translate }} </small>

            <input [formControl]="form.controls['lastName_customer']"  type="text" [placeholder]="'LOGIN.LAST_NAME_PLACEHOLDER' | translate">
            <small
                                            *ngIf="form.controls['lastName_customer'].hasError('required') && form.controls['lastName_customer'].touched"
                                            class="form-error-msg"> {{ 'LOGIN.LAST_NAME_REQUIRED' | translate }}</small>
            <input [formControl]="form.controls['identifier_customer']"  type="text"  [placeholder]="'LOGIN.IDENTIFIER_PLACEHOLDER' | translate">
            <small
                                            *ngIf="form.controls['identifier_customer'].hasError('required') && form.controls['identifier_customer'].touched"
                                            class="form-error-msg"> {{ 'LOGIN.IDENTIFIER_REQUIRED' | translate }} </small>
            <input [formControl]="form.controls['email_customer']" type="text" [placeholder]="'LOGIN.EMAIL_PLACEHOLDER' | translate">
            <small
                                            *ngIf="form.controls['email_customer'].hasError('required') && form.controls['email_customer'].touched"
                                            class="form-error-msg"> {{ 'LOGIN.EMAIL_REQUIRED' | translate }} </small>

            <input [formControl]="form.controls['phoneNumber_customer']"  type="text" [placeholder]="'LOGIN.PHONE_PLACEHOLDER' | translate">
            <div *ngIf="showButtons" class="options">
              <button>{{ 'LOGIN.REGISTER_BUTTON' | translate }}</button>
            </div>
          </form>

         
        </div>
        <div class="transparent-background">
          <p>{{ 'LOGIN.ALREADY_HAVE_ACCOUNT' | translate }}</p>
          <a (click)="goToLogin()">{{ 'LOGIN.GO_TO_LOGIN' | translate }}</a>
        </div>
      </div>
    </div>
    <img src="../../assets/images/grafica2.png" alt="graff">
  </div>
  