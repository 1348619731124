<button class="close" mat-icon-button (click)="closeDialog()">
  <mat-icon>close</mat-icon>
</button>

<div class="form_dialog">
  <h1>{{ 'CARD.ADD_TITLE' | translate }}</h1>
  <form [formGroup]="form">
    <div class="form-group">
      <input [formControlName]="'nameHolderCreditCard'" type="text" id="nombreTitular" name="tarjeta" placeholder="{{ 'CARD.FORM.CARD_NAME' | translate }}" required>
      <small *ngIf="form.controls['nameHolderCreditCard'].hasError('required') && form.controls['nameHolderCreditCard'].touched" class="form-error-msg">
        {{ 'CARD.FORM.CARD_NAME_REQUIRED' | translate }}
      </small>
    </div>
    <div class="form-group">
      <input (input)="formatCreditCardNumber()" (keypress)="onlyNumberKey($event)" [formControlName]="'numberCreditCard'" type="text" id="numero-tarjeta" name="numeroTarjeta" placeholder="{{ 'CARD.FORM.CARD_NUMBER' | translate }}" required>
      <small *ngIf="form.controls['numberCreditCard'].hasError('required') && form.controls['numberCreditCard'].touched" class="form-error-msg">
        {{ 'CARD.FORM.CARD_NUMBER_REQUIRED' | translate }}
      </small>
      <small *ngIf="form.controls['numberCreditCard'].hasError('invalidLength') && form.controls['numberCreditCard'].touched" class="form-error-msg">
        {{ 'CARD.FORM.INVALID_LENGTH' | translate }}
      </small>
      <small *ngIf="form.controls['numberCreditCard'].hasError('invalidCardNumber') && form.controls['numberCreditCard'].touched" class="form-error-msg">
        {{ 'CARD.FORM.INVALID_CARD_NUMBER' | translate }}
      </small>
    </div>
    <div class="form-group">
      <select [formControlName]="'expMonthCreditCard'" id="mesExpiracion" name="mes-expiracion" required>
        <option value="" disabled selected>{{ 'CARD.FORM.SELECT_MONTH' | translate }}</option>
        <option *ngFor="let mes of meses" [value]="mes.value">{{ mes.desc }}</option>
      </select>
      <small *ngIf="form.controls['expMonthCreditCard'].hasError('required') && form.controls['expMonthCreditCard'].touched" class="form-error-msg">
        {{ 'CARD.FORM.MONTH_REQUIRED' | translate }}
      </small>
    </div>
    <div class="form-group">
      <select [formControlName]="'expYearCreditCard'" id="añoExpiracion" name="ano-expiracion" required>
        <option value="" disabled selected>{{ 'CARD.FORM.SELECT_YEAR' | translate }}</option>
        <option *ngFor="let year of years" [value]="year.value">{{ year.desc }}</option>
      </select>
      <small *ngIf="form.controls['expYearCreditCard'].hasError('required') && form.controls['expYearCreditCard'].touched" class="form-error-msg">
        {{ 'CARD.FORM.YEAR_REQUIRED' | translate }}
      </small>
    </div>
    <div class="form-group">
      <input maxlength="4" (keypress)="onlyNumberKey($event)" [formControlName]="'ccv'" type="password" id="cvv" name="cvv" placeholder="{{ 'CARD.FORM.CVV' | translate }}" required>
      <small *ngIf="form.controls['ccv'].hasError('required') && form.controls['ccv'].touched" class="form-error-msg">
        {{ 'CARD.FORM.CVV_REQUIRED' | translate }}
      </small>
    </div>
    <div class="form-group">
      <input [formControlName]="'city'" type="text" id="city" name="city" placeholder="{{ 'CARD.FORM.CITY' | translate }}" required>
      <small *ngIf="form.controls['city'].hasError('required') && form.controls['city'].touched" class="form-error-msg">
        {{ 'CARD.FORM.CITY_REQUIRED' | translate }}
      </small>
    </div>
    <div class="form-group">
      <input [formControlName]="'street'" type="text" id="street" name="street" placeholder="{{ 'CARD.FORM.STREET' | translate }}" required>
      <small *ngIf="form.controls['street'].hasError('required') && form.controls['street'].touched" class="form-error-msg">
        {{ 'CARD.FORM.STREET_REQUIRED' | translate }}
      </small>
    </div>
    <div class="form-group">
      <input [formControlName]="'postalCode'" type="text" id="code" name="tarjeta" placeholder="{{ 'CARD.FORM.POSTAL_CODE' | translate }}" required (keypress)="onlyNumberKey($event)">
      <small *ngIf="form.controls['postalCode'].hasError('required') && form.controls['postalCode'].touched" class="form-error-msg">
        {{ 'CARD.FORM.POSTAL_CODE_REQUIRED' | translate }}
      </small>
      <small *ngIf="form.controls['postalCode'].hasError('pattern') && form.controls['postalCode'].touched" class="form-error-msg">
        {{ 'CARD.FORM.POSTAL_CODE_INVALID' | translate }}
      </small>
    </div>
    <div class="form-group">
      <input [formControlName]="'phone'" type="text" id="phone" name="phone" placeholder="{{ 'CARD.FORM.PHONE' | translate }}" required (keypress)="onlyNumberKey($event)">
      <small *ngIf="form.controls['phone'].hasError('required') && form.controls['phone'].touched" class="form-error-msg">
        {{ 'CARD.FORM.PHONE_REQUIRED' | translate }}
      </small>
      <small *ngIf="form.controls['phone'].hasError('pattern') && form.controls['phone'].touched" class="form-error-msg">
        {{ 'CARD.FORM.PHONE_INVALID' | translate }}
      </small>
    </div>
  </form>
  <button class="create" (click)="create()">{{ 'CARD.FORM.CREATE_BUTTON' | translate }}</button>
</div>
