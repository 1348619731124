import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../services/loader.service';
import { NotificationService } from '../services/notification.service';
import { WebService } from '../services/web.service';
import { ThemeService } from '../theme.service';
import { LanguageService } from '../services/language.service';
@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent {
  form : any;
  themeColors: any; 
  isPreview : Boolean = false;
  param : any;
  constructor(public languageService: LanguageService,public themeService: ThemeService,private activateRoute : ActivatedRoute,private router : Router,private web : WebService, private notification : NotificationService, private loader : LoaderService) {


    this.form = new FormGroup({
      email: new FormControl('', [Validators.required,Validators.email]),
      partner : new FormControl('', Validators.required),
      service : new FormControl('', Validators.required)
    });

    this.activateRoute.params.subscribe( params =>{
      let param = params['id'] != undefined ? params['id'] : -1;
      this.loader.open();
      this.isPreview = params["id"] != undefined ? true : false;
      if(this.isPreview){
       this.param = params["id"]
      }
      // this.web.get(this.web.HOST + "/onboardingConfig/bydomain/" + "dev.onboarding.practic.billcentric.com" + "/"  + 235).subscribe( response =>{ 
      // this.web.get(this.web.HOST + "/onboardingConfig/bydomain/" + "laganga.billcentric.com" + "/"  + param).subscribe( response =>{ 
       this.web.get(this.web.HOST + "/onboardingConfig/bydomain/" + window.location.host + "/"  + param ).subscribe( response =>{
        this.loader.close();

        this.form.controls["partner"].setValue(response.result.partner.id);
        this.form.controls["service"].setValue(response.result.service.id);

        // if(param === -1 && (response.result.customerHasPlan != null && response.result.customerHasPlan.status_customerPlan == 1) ){
        //   this.form.controls["partner"].setValue(response.result.partner.id);
        //   this.form.controls["service"].setValue(response.result.service.id);
        // }else{
        //   return this.notification.showError("Por favor, culmina el pago de tu portal para que se pueda desplegar correctamente...");
        // }
        
       
      }, err =>{
        this.loader.close();
        this.notification.showError(err);
      });
      
    });


  }
  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }

  reset(){

    if(!this.form.valid){
      return this.notification.showError("Por favor complete el formulario...");
    }
    this.loader.open();
    // this.form.partner = localStorage.getItem("partner");
    //console.log(this.form.value)
    this.web.post(this.form.value, this.web.HOST + "/onboarding/user/resetPassword").subscribe(response =>{
        this.loader.close();
        this.notification.showSuccess("Se enviara un nuevo password a su correo, por favor inicie sesión con sus credenciales nuevas.");
        this.goToLogin()
    }, err =>{
      //console.log(err);
      this.loader.close();
      if(err.code == "not_found"){
        this.notification.showError("Disculpe, este correo no posee cuenta asociada");
      }else{
        this.notification.showError(err.message);
      }
     
    });

  }

  goToLogin(){
    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param + "/login"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/login"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }
  }


}
