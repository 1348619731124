<div class="loading-overlay" *ngIf="loading">
    <div class="spinner"></div>
  </div>
  
  <ng-container *ngIf="!loading">
    <!-- Aquí va tu lógica de *ngSwitch para mostrar 
         <app-basic>, <app-contempo>, etc. -->
  
    <ng-container [ngSwitch]="plantilla">
      <app-basic *ngSwitchCase="'basic'"></app-basic>
      <app-contempo *ngSwitchCase="'contempo'"></app-contempo>
      <app-practic *ngSwitchCase="'practic'"></app-practic>
  
      <div *ngSwitchDefault>
        <p>No se encontró la plantilla...</p>
      </div>
    </ng-container>
  </ng-container>
  