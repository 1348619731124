import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../theme.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PlanService } from '../services/plan/plan.service';
import { ImagesService } from '../services/image/images.service';
import { WebService } from '../services/web.service';
import { NotificationService } from '../services/notification.service';
import { LoaderService } from '../services/loader.service';
import { Plan } from '../components/plan/model/plan.model';
import { LanguageService } from '../services/language.service';
import { TemplateDataService } from '../services/template-data.service';

@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss']
})
export class basicComponent implements OnInit {
  selectedPalette: string = 'Billcentrix';
  plans: Plan[] = [];

  title: any = "";
  description: any = "";
  price_title: any = "";
  banner_title: any = "";
  banner_description: any = "";
  class: any = "pal1";
  imagePrincipal: any;
  imagePromotional: any = null;
  isSuscriptionActive = true;
  aloudNavigation = true;

  constructor(
    public languageService: LanguageService,
    public themeService: ThemeService,
    private activateRoute: ActivatedRoute,
    private planService: PlanService,
    private imageService: ImagesService,
    private web: WebService,
    private notification: NotificationService,
    private loader: LoaderService,
    private templateDataService: TemplateDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loader.open();

    this.activateRoute.params.subscribe(params => {
      let param = params["id"] !== undefined ? params["id"] : -1;
      this.aloudNavigation = (param == -1);
      if (!this.aloudNavigation) {
        this.notification.showInfo("Este modo NO permite generar suscripciones");
      }

      // Nos suscribimos al observable para obtener el objeto real de configuración
      this.templateDataService.getResponse().subscribe(response => {
        if (!response) {
          this.loader.close();
          this.notification.showInfo("No se encontró la configuración. Redirigiendo...");
          this.router.navigate(['/']);
          return;
        }
        // Procesamos la respuesta si existe y tiene la estructura esperada
        if (response.result && response.result.onboardingImages) {
          this.showContentPage(response);
        } else {
          this.notification.showInfo("La respuesta no tiene la estructura esperada.");
        }
        this.loader.close();
      });
    });
  }

  showContentPage(response: any): void {
    // Verificamos que existan las imágenes
    if (response.result.onboardingImages != null) {
      this.imagePrincipal = response.result.onboardingImages.principal_banner;

      if (response.result.customerHasPlan != null) {
        const nexBillDate = new Date(response.result.customerHasPlan.next_bill_date);
        const today = new Date();
        const isValidDate = nexBillDate > today;
        if (response.result.customerHasPlan != null &&
            (response.result.customerHasPlan.status_customerPlan !== 1 || !isValidDate)) {
          this.isSuscriptionActive = false;
        }
        this.planService.isSuscriptionActive.emit(this.isSuscriptionActive);
        this.show(response);
      } else {
        this.planService.isSuscriptionActive.emit(this.isSuscriptionActive);
        this.show(response);
      }
    } else {
      this.loader.close();
      this.notification.showInfo("Por favor culmina tu portal...");
    }
  }

  show(response: any): void {
    // Recorremos y filtramos solo los planes activos
    for (let plan of response.result.plans) {
      if (plan.status_plan == 1) {
        this.plans.push(
          new Plan(
            plan.name_plan,
            plan.feeCost_plan,
            plan.description,
            plan.url_image,
            plan.id,
            plan.service,
            plan.is_visible_plan
          )
        );
      }
    }
    console.log("Basic - Filtered plans:", this.plans);
    this.planService.obtainPlans.emit(this.plans);
    this.imagePrincipal = response.result.onboardingImages.principal_banner;
    this.imagePromotional = response.result.onboardingImages.promotional_banner;
    localStorage.setItem("service", response.result.service.id);
    localStorage.setItem("partner", response.result.partner.id);
    localStorage.setItem("step", this.isSuscriptionActive.toString());
    this.imageService.setLogoImage(response.result.onboardingImages.logo);
    this.imageService.logoImage.emit(response.result.onboardingImages.logo);
    this.imageService.setPrincipalBannerImage(response.result.onboardingImages.principal_banner);
    this.class = response.result.color;
    this.themeService.setPalette(response.result.color);
    this.title = response.result.onboardingPortalContent.page_title;
    this.description = response.result.onboardingPortalContent.description_page;
    this.price_title = response.result.onboardingPortalContent.price_title;
    this.banner_title = response.result.onboardingPortalContent.banner_title;
    this.banner_description = response.result.onboardingPortalContent.description_banner;
  }

  changePalette(palette: string): void {
    this.themeService.setPalette(palette);
  }

  // (Opcional) Método para cargar data si se ingresa directo a /basic
  private loadDataFromEndpoint(param: number) {
    this.web.get(this.web.HOST + "/onboardingConfig/bydomain/" + window.location.host + "/" + param)
      .subscribe({
        next: (response) => {
          this.showContentPage(response);
        },
        error: (err) => {
          this.loader.close();
          this.notification.showError(err);
        }
      });
  }
}
