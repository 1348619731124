import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ThemeService } from '../../theme.service';
import { AddCardFormComponent } from '../add-card-form/add-card-form.component';
import { MatDialog } from '@angular/material/dialog';
import { AddAddressComponent } from '../add-address/add-address.component';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { WebService } from 'src/app/services/web.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent implements OnInit {

  // Arreglo final con las tarjetas elegibles tras la comparación
  tdc: any[] = [];
  // Tarjetas activas obtenidas del servidor (para comparación)
  tarjetasActivasCorer: any[] = [];
  // Tarjetas registradas del cliente (para filtrar)
  tarjetasRegistradasCustomer: any[] = [];
  
  themeColors: any; 
  isAddCardFormVisible = false; 
  isDialogOpen = false;

  // Paginación tradicional
  currentPage = 1;       // Página actual a mostrar
  itemsPerPage = 5;      // Cantidad de tarjetas por página
  public totalPages = 0; // Total de páginas según los datos acumulados

  // Parámetros para la carga bajo demanda (lazy load) de 5 en 5
  serverPageIndex = 0;       // Índice de página en la consulta al servidor
  initialBatchSize = 5;      // Tamaño del lote inicial (5)
  subsequentBatchSize = 5;   // Tamaño de lotes para cargas posteriores (5)
  currentBatchSize = this.initialBatchSize;
  allDataLoaded = false;     // Se marca true cuando ya no hay más datos en el servidor
  loadingMore = false;       // Flag para evitar llamadas duplicadas

  constructor(
    public themeService: ThemeService,
    private dialog: MatDialog,
    private auth: AuthService,
    private web: WebService,
    private notification: NotificationService,
    private loader: LoaderService
  ) {}

  ngOnInit() {
    this.loader.open();
    // Primero, obtener las tarjetas registradas del cliente (sin paginar)
    this.web.get(this.web.HOST + '/creditcard/customer/' + this.auth.getCustomer())
      .subscribe(response => {
        this.tarjetasRegistradasCustomer = (response['result'] && response['result'].length > 0)
          ? response['result']
          : [];
        // Con las tarjetas registradas disponibles, cargar el primer lote de tarjetas activas
        this.loadMoreCards(() => {
          this.ensurePageComplete(() => {
            this.updateTotalPages();
            this.loader.close();
          });
        });
      }, error => {
        this.loader.close();
      });
  }

  /**
   * Realiza una petición al servidor para obtener un lote de tarjetas activas (de 5 en 5),
   * filtra las tarjetas que tengan coincidencia con las registradas y las agrega a 'tdc' (evitando duplicados).
   * Si se recibe menos de lo solicitado, se marca que ya no hay más datos (allDataLoaded).
   * Se acepta un callback para continuar la cadena asíncrona.
   */
  loadMoreCards(callback?: () => void) {
    if (this.loadingMore) { return; }
    this.loadingMore = true;
    let httpParams = this.web.buildRequestParams(null, "m", { 
      pageIndex: this.serverPageIndex, 
      pageSize: this.currentBatchSize 
    });
    this.web.get(this.web.HOST + "/customer/list/" + this.auth.getCustomer() + "/creditcard", httpParams)
      .subscribe(response => {
        this.tarjetasActivasCorer = response.result;
        // Si se reciben menos registros que el tamaño solicitado, se asume que ya se han cargado todos los datos
        if (this.tarjetasActivasCorer.length < this.currentBatchSize) {
          this.allDataLoaded = true;
        }
        // Filtrado: para cada tarjeta registrada, se verifica si existe alguna tarjeta activa con el mismo ID
        const tarjetasFiltradas = this.tarjetasRegistradasCustomer.filter(tarjetaCustomer =>
          this.tarjetasActivasCorer.some(tarjetaCorer => {
            const coinciden = tarjetaCustomer.corerId === tarjetaCorer.id;
            if (coinciden) {
              console.log(`Coinciden: Tarjeta registrada (corerId: ${tarjetaCustomer.corerId}) coincide con tarjeta activa (id: ${tarjetaCorer.id}).`);
            }
            return coinciden;
          })
        );
        // Para cada tarjeta filtrada, se agrega a 'tdc' si aún no existe
        tarjetasFiltradas.forEach(tarjeta => {
          if (!this.tdc.some(item => item.id === tarjeta.corerId)) {
            console.log(`Agregando tarjeta con corerId: ${tarjeta.corerId}`);
            this.tdc.push({
              id: tarjeta.corerId,
              type: tarjeta.creditCardProvider.description,
              number: tarjeta.mask_card,
              status: 1,
              token: tarjeta.token_creditCardToken
            });
          }
        });
        this.loadingMore = false;
        if (callback) { callback(); }
      }, error => {
        this.loadingMore = false;
        this.loader.close();
      });
  }

  /**
   * Verifica si la página actual (definida por currentPage e itemsPerPage) tiene la cantidad necesaria de elementos.
   * Si no es así y aún quedan datos por cargar (allDataLoaded es false), incrementa el índice del servidor y
   * dispara una nueva petición (loadMoreCards) hasta que se completen 5 elementos o se agoten los datos.
   */
  ensurePageComplete(callback?: () => void) {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const pageSlice = this.tdc.slice(start, start + this.itemsPerPage);
    if (pageSlice.length < this.itemsPerPage && !this.allDataLoaded) {
      console.log(`Página ${this.currentPage} incompleta con ${pageSlice.length} elementos. Se requiere cargar más datos.`);
      this.serverPageIndex++;
      this.loadMoreCards(() => {
        this.ensurePageComplete(callback);
      });
    } else {
      if (callback) { callback(); }
    }
  }

  /**
   * Actualiza el total de páginas basado en la cantidad de elementos acumulados en 'tdc'.
   * Si se han cargado todos los datos, se usa Math.ceil para incluir la última página parcial;
   * de lo contrario, se usan solo páginas completas (Math.floor).
   */
  updateTotalPages() {
    if (this.allDataLoaded) {
      this.totalPages = Math.ceil(this.tdc.length / this.itemsPerPage);
    } else {
      this.totalPages = Math.floor(this.tdc.length / this.itemsPerPage);
      if (this.tdc.length > 0 && this.totalPages === 0) {
        this.totalPages = 1;
      }
    }
  }

  /**
   * Al avanzar a la siguiente página, se incrementa currentPage y se verifica que la nueva página
   * tenga al menos 5 elementos. Si no es así, se dispara automáticamente la carga de nuevos datos.
   */
  nextPage(): void {
    this.currentPage++;
    this.ensurePageComplete(() => {
      this.updateTotalPages();
    });
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  changePage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }

  toggleAddCardForm() {
    if (!this.isDialogOpen) {
      this.isDialogOpen = true;
      const dialogRef = this.dialog.open(AddCardFormComponent, {
        width: '800px',
        height: '500px'
      });
      dialogRef.afterClosed().subscribe(() => {
        this.isDialogOpen = false;
        window.location.reload();
      });
    }
  }
  get paginationDisplay(): string {
    if (this.allDataLoaded) {
      return `Página ${this.currentPage} de ${this.totalPages}`;
    } else {
      return `Página ${this.currentPage}`; // o "Página X de ..." si prefieres
    }
  }
  changeStatus(tdc: any) {
    this.loader.open();
    if (!tdc.id) {
      this.loader.close();
      return this.notification.showInfo("No se puede eliminar la tarjeta");
    }
    const action = tdc.status.status === 1 ? 'delete' : 'activate';
    const url = `${this.web.HOST}/customer/${this.auth.getCustomer()}/creditcard/${tdc.id}/${action}`;
    const json = { token: tdc.status.token };
    this.web.post(json, url).subscribe(
      () => {
        this.loader.close();
        this.notification.showSuccess("Actualizada correctamente");
        window.location.reload();
      },
      () => {
        this.loader.close();
        this.notification.showError("No se pudo cambiar el estado de la tarjeta, verifique que no se encuentre asociada a un plan activo");
      }
    );
  }
}
